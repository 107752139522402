"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _StorageController = _interopRequireDefault(require("./StorageController.react-native"));
var _StorageController2 = _interopRequireDefault(require("./StorageController.browser"));
var _StorageController3 = _interopRequireDefault(require("./StorageController.weapp"));
var _StorageController4 = _interopRequireDefault(require("./StorageController.default"));
let StorageController = _StorageController4.default;
StorageController = _StorageController2.default;
module.exports = StorageController;
var _default = exports.default = StorageController;