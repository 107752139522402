"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
/**
 * This is a simple wrapper to unify EventEmitter implementations across platforms.
 */

let EventEmitter;
try {
  EventEmitter = require('events').EventEmitter;
} catch (_) {
  // EventEmitter unavailable
}
module.exports = EventEmitter;
var _default = exports.default = EventEmitter;