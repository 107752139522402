"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.PIN_PREFIX = exports.OBJECT_PREFIX = exports.DEFAULT_PIN = void 0;
exports.isLocalDatastoreKey = isLocalDatastoreKey;
var _startsWith = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/starts-with"));
const DEFAULT_PIN = exports.DEFAULT_PIN = '_default';
const PIN_PREFIX = exports.PIN_PREFIX = 'parsePin_';
const OBJECT_PREFIX = exports.OBJECT_PREFIX = 'Parse_LDS_';
function isLocalDatastoreKey(key) {
  return !!(key && (key === DEFAULT_PIN || (0, _startsWith.default)(key).call(key, PIN_PREFIX) || (0, _startsWith.default)(key).call(key, OBJECT_PREFIX)));
}