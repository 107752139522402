"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _stringify = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/json/stringify"));
var _reduce = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/reduce"));
var _promise = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/promise"));
var _map = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/map"));
var _LocalDatastoreUtils = require("./LocalDatastoreUtils");
var _Storage = _interopRequireDefault(require("./Storage"));
const LocalDatastoreController = {
  async fromPinWithName(name) {
    const values = await _Storage.default.getItemAsync(name);
    if (!values) {
      return [];
    }
    const objects = JSON.parse(values);
    return objects;
  },
  pinWithName(name, value) {
    const values = (0, _stringify.default)(value);
    return _Storage.default.setItemAsync(name, values);
  },
  unPinWithName(name) {
    return _Storage.default.removeItemAsync(name);
  },
  async getAllContents() {
    const keys = await _Storage.default.getAllKeysAsync();
    return (0, _reduce.default)(keys).call(keys, async (previousPromise, key) => {
      const LDS = await previousPromise;
      if ((0, _LocalDatastoreUtils.isLocalDatastoreKey)(key)) {
        const value = await _Storage.default.getItemAsync(key);
        try {
          LDS[key] = JSON.parse(value);
        } catch (error) {
          console.error('Error getAllContents: ', error);
        }
      }
      return LDS;
    }, _promise.default.resolve({}));
  },
  // Used for testing
  async getRawStorage() {
    const keys = await _Storage.default.getAllKeysAsync();
    return (0, _reduce.default)(keys).call(keys, async (previousPromise, key) => {
      const LDS = await previousPromise;
      const value = await _Storage.default.getItemAsync(key);
      LDS[key] = value;
      return LDS;
    }, _promise.default.resolve({}));
  },
  async clear() {
    const keys = await _Storage.default.getAllKeysAsync();
    const toRemove = [];
    for (const key of keys) {
      if ((0, _LocalDatastoreUtils.isLocalDatastoreKey)(key)) {
        toRemove.push(key);
      }
    }
    const promises = (0, _map.default)(toRemove).call(toRemove, this.unPinWithName);
    return _promise.default.all(promises);
  }
};
module.exports = LocalDatastoreController;
var _default = exports.default = LocalDatastoreController;