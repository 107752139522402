"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ws = _interopRequireDefault(require("ws"));
var _Socket = _interopRequireDefault(require("./Socket.weapp"));
/* global WebSocket */

let WebSocketController;
try {
  WebSocketController = typeof WebSocket === 'function' || typeof WebSocket === 'object' ? WebSocket : null;
} catch (_) {
  // WebSocket unavailable
}
module.exports = WebSocketController;
var _default = exports.default = WebSocketController;