"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.default = parseDate;
var _parseInt2 = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/parse-int"));
function parseDate(iso8601) {
  const regexp = new RegExp('^([0-9]{1,4})-([0-9]{1,2})-([0-9]{1,2})' + 'T' + '([0-9]{1,2}):([0-9]{1,2}):([0-9]{1,2})' + '(.([0-9]+))?' + 'Z$');
  const match = regexp.exec(iso8601);
  if (!match) {
    return null;
  }
  const year = (0, _parseInt2.default)(match[1]) || 0;
  const month = ((0, _parseInt2.default)(match[2]) || 1) - 1;
  const day = (0, _parseInt2.default)(match[3]) || 0;
  const hour = (0, _parseInt2.default)(match[4]) || 0;
  const minute = (0, _parseInt2.default)(match[5]) || 0;
  const second = (0, _parseInt2.default)(match[6]) || 0;
  const milli = (0, _parseInt2.default)(match[8]) || 0;
  return new Date(Date.UTC(year, month, day, hour, minute, second, milli));
}