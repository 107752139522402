"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _promise = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/promise"));
var _indexOf = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/index-of"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/defineProperty"));
var _LiveQueryClient = _interopRequireDefault(require("./LiveQueryClient"));
var _CoreManager = _interopRequireDefault(require("./CoreManager"));
function getLiveQueryClient() {
  return _CoreManager.default.getLiveQueryController().getDefaultLiveQueryClient();
}

/**
 * We expose three events to help you monitor the status of the WebSocket connection:
 *
 * <p>Open - When we establish the WebSocket connection to the LiveQuery server, you'll get this event.
 *
 * <pre>
 * Parse.LiveQuery.on('open', () => {
 *
 * });</pre></p>
 *
 * <p>Close - When we lose the WebSocket connection to the LiveQuery server, you'll get this event.
 *
 * <pre>
 * Parse.LiveQuery.on('close', () => {
 *
 * });</pre></p>
 *
 * <p>Error - When some network error or LiveQuery server error happens, you'll get this event.
 *
 * <pre>
 * Parse.LiveQuery.on('error', (error) => {
 *
 * });</pre></p>
 *
 * @class Parse.LiveQuery
 * @static
 */
class LiveQuery {
  constructor() {
    var _this = this;
    (0, _defineProperty2.default)(this, "emitter", void 0);
    (0, _defineProperty2.default)(this, "on", void 0);
    (0, _defineProperty2.default)(this, "emit", void 0);
    const EventEmitter = _CoreManager.default.getEventEmitter();
    this.emitter = new EventEmitter();
    this.on = (eventName, listener) => this.emitter.on(eventName, listener);
    this.emit = function (eventName) {
      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }
      return _this.emitter.emit(eventName, ...args);
    };
    // adding listener so process does not crash
    // best practice is for developer to register their own listener
    this.on('error', () => {});
  }

  /**
   * After open is called, the LiveQuery will try to send a connect request
   * to the LiveQuery server.
   */
  async open() {
    const liveQueryClient = await getLiveQueryClient();
    liveQueryClient.open();
  }

  /**
   * When you're done using LiveQuery, you can call Parse.LiveQuery.close().
   * This function will close the WebSocket connection to the LiveQuery server,
   * cancel the auto reconnect, and unsubscribe all subscriptions based on it.
   * If you call query.subscribe() after this, we'll create a new WebSocket
   * connection to the LiveQuery server.
   */
  async close() {
    const liveQueryClient = await getLiveQueryClient();
    liveQueryClient.close();
  }
}
var _default = exports.default = LiveQuery;
let defaultLiveQueryClient;
const DefaultLiveQueryController = {
  setDefaultLiveQueryClient(liveQueryClient) {
    defaultLiveQueryClient = liveQueryClient;
  },
  async getDefaultLiveQueryClient() {
    if (defaultLiveQueryClient) {
      return defaultLiveQueryClient;
    }
    const [currentUser, installationId] = await _promise.default.all([_CoreManager.default.getUserController().currentUserAsync(), _CoreManager.default.getInstallationController().currentInstallationId()]);
    const sessionToken = currentUser ? currentUser.getSessionToken() : undefined;
    let liveQueryServerURL = _CoreManager.default.get('LIVEQUERY_SERVER_URL');
    if (liveQueryServerURL && (0, _indexOf.default)(liveQueryServerURL).call(liveQueryServerURL, 'ws') !== 0) {
      throw new Error('You need to set a proper Parse LiveQuery server url before using LiveQueryClient');
    }

    // If we can not find Parse.liveQueryServerURL, we try to extract it from Parse.serverURL
    if (!liveQueryServerURL) {
      const serverURL = _CoreManager.default.get('SERVER_URL');
      const protocol = (0, _indexOf.default)(serverURL).call(serverURL, 'https') === 0 ? 'wss://' : 'ws://';
      const host = serverURL.replace(/^https?:\/\//, '');
      liveQueryServerURL = protocol + host;
      _CoreManager.default.set('LIVEQUERY_SERVER_URL', liveQueryServerURL);
    }
    const applicationId = _CoreManager.default.get('APPLICATION_ID');
    const javascriptKey = _CoreManager.default.get('JAVASCRIPT_KEY');
    const masterKey = _CoreManager.default.get('MASTER_KEY');
    defaultLiveQueryClient = new _LiveQueryClient.default({
      applicationId,
      serverURL: liveQueryServerURL,
      javascriptKey,
      masterKey,
      sessionToken,
      installationId
    });
    const LiveQuery = _CoreManager.default.getLiveQuery();
    defaultLiveQueryClient.on('error', error => {
      LiveQuery.emit('error', error);
    });
    defaultLiveQueryClient.on('open', () => {
      LiveQuery.emit('open');
    });
    defaultLiveQueryClient.on('close', () => {
      LiveQuery.emit('close');
    });
    return defaultLiveQueryClient;
  },
  _clearCachedDefaultClient() {
    defaultLiveQueryClient = null;
  }
};
_CoreManager.default.setLiveQueryController(DefaultLiveQueryController);