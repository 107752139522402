"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
/* global localStorage */

const StorageController = {
  async: 0,
  getItem(path) {
    return localStorage.getItem(path);
  },
  setItem(path, value) {
    try {
      localStorage.setItem(path, value);
    } catch (e) {
      // Quota exceeded, possibly due to Safari Private Browsing mode
      console.log(e.message);
    }
  },
  removeItem(path) {
    localStorage.removeItem(path);
  },
  getAllKeys() {
    const keys = [];
    for (let i = 0; i < localStorage.length; i += 1) {
      keys.push(localStorage.key(i));
    }
    return keys;
  },
  clear() {
    localStorage.clear();
  }
};
module.exports = StorageController;
var _default = exports.default = StorageController;