"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.UnsetOp = exports.SetOp = exports.RemoveOp = exports.RelationOp = exports.Op = exports.IncrementOp = exports.AddUniqueOp = exports.AddOp = void 0;
exports.opFromJSON = opFromJSON;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/defineProperty"));
var _isArray = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/array/is-array"));
var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));
var _forEach = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/for-each"));
var _indexOf = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/index-of"));
var _splice = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/splice"));
var _map = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/map"));
var _arrayContainsObject = _interopRequireDefault(require("./arrayContainsObject"));
var _decode = _interopRequireDefault(require("./decode"));
var _encode = _interopRequireDefault(require("./encode"));
var _CoreManager = _interopRequireDefault(require("./CoreManager"));
var _ParseRelation = _interopRequireDefault(require("./ParseRelation"));
var _unique = _interopRequireDefault(require("./unique"));
function opFromJSON(json) {
  if (!json || !json.__op) {
    return null;
  }
  switch (json.__op) {
    case 'Delete':
      return new UnsetOp();
    case 'Increment':
      return new IncrementOp(json.amount);
    case 'Add':
      return new AddOp((0, _decode.default)(json.objects));
    case 'AddUnique':
      return new AddUniqueOp((0, _decode.default)(json.objects));
    case 'Remove':
      return new RemoveOp((0, _decode.default)(json.objects));
    case 'AddRelation':
      {
        const toAdd = (0, _decode.default)(json.objects);
        if (!(0, _isArray.default)(toAdd)) {
          return new RelationOp([], []);
        }
        return new RelationOp(toAdd, []);
      }
    case 'RemoveRelation':
      {
        const toRemove = (0, _decode.default)(json.objects);
        if (!(0, _isArray.default)(toRemove)) {
          return new RelationOp([], []);
        }
        return new RelationOp([], toRemove);
      }
    case 'Batch':
      {
        let toAdd = [];
        let toRemove = [];
        for (let i = 0; i < json.ops.length; i++) {
          if (json.ops[i].__op === 'AddRelation') {
            toAdd = (0, _concat.default)(toAdd).call(toAdd, (0, _decode.default)(json.ops[i].objects));
          } else if (json.ops[i].__op === 'RemoveRelation') {
            toRemove = (0, _concat.default)(toRemove).call(toRemove, (0, _decode.default)(json.ops[i].objects));
          }
        }
        return new RelationOp(toAdd, toRemove);
      }
  }
  return null;
}
class Op {
  // Empty parent class
  applyTo() {} /* eslint-disable-line @typescript-eslint/no-unused-vars */
  mergeWith() {} /* eslint-disable-line @typescript-eslint/no-unused-vars */
  toJSON() {} /* eslint-disable-line @typescript-eslint/no-unused-vars */
}
exports.Op = Op;
class SetOp extends Op {
  constructor(value) {
    super();
    (0, _defineProperty2.default)(this, "_value", void 0);
    this._value = value;
  }
  applyTo() {
    return this._value;
  }
  mergeWith() {
    return new SetOp(this._value);
  }
  toJSON(offline) {
    return (0, _encode.default)(this._value, false, true, undefined, offline);
  }
}
exports.SetOp = SetOp;
class UnsetOp extends Op {
  applyTo() {
    return undefined;
  }
  mergeWith() {
    return new UnsetOp();
  }
  toJSON() {
    return {
      __op: 'Delete'
    };
  }
}
exports.UnsetOp = UnsetOp;
class IncrementOp extends Op {
  constructor(amount) {
    super();
    (0, _defineProperty2.default)(this, "_amount", void 0);
    if (typeof amount !== 'number') {
      throw new TypeError('Increment Op must be initialized with a numeric amount.');
    }
    this._amount = amount;
  }
  applyTo(value) {
    if (typeof value === 'undefined') {
      return this._amount;
    }
    if (typeof value !== 'number') {
      throw new TypeError('Cannot increment a non-numeric value.');
    }
    return this._amount + value;
  }
  mergeWith(previous) {
    if (!previous) {
      return this;
    }
    if (previous instanceof SetOp) {
      return new SetOp(this.applyTo(previous._value));
    }
    if (previous instanceof UnsetOp) {
      return new SetOp(this._amount);
    }
    if (previous instanceof IncrementOp) {
      return new IncrementOp(this.applyTo(previous._amount));
    }
    throw new Error('Cannot merge Increment Op with the previous Op');
  }
  toJSON() {
    return {
      __op: 'Increment',
      amount: this._amount
    };
  }
}
exports.IncrementOp = IncrementOp;
class AddOp extends Op {
  constructor(value) {
    super();
    (0, _defineProperty2.default)(this, "_value", void 0);
    this._value = (0, _isArray.default)(value) ? value : [value];
  }
  applyTo(value) {
    if (value == null) {
      return this._value;
    }
    if ((0, _isArray.default)(value)) {
      return (0, _concat.default)(value).call(value, this._value);
    }
    throw new Error('Cannot add elements to a non-array value');
  }
  mergeWith(previous) {
    if (!previous) {
      return this;
    }
    if (previous instanceof SetOp) {
      return new SetOp(this.applyTo(previous._value));
    }
    if (previous instanceof UnsetOp) {
      return new SetOp(this._value);
    }
    if (previous instanceof AddOp) {
      return new AddOp(this.applyTo(previous._value));
    }
    throw new Error('Cannot merge Add Op with the previous Op');
  }
  toJSON() {
    return {
      __op: 'Add',
      objects: (0, _encode.default)(this._value, false, true)
    };
  }
}
exports.AddOp = AddOp;
class AddUniqueOp extends Op {
  constructor(value) {
    super();
    (0, _defineProperty2.default)(this, "_value", void 0);
    this._value = (0, _unique.default)((0, _isArray.default)(value) ? value : [value]);
  }
  applyTo(value) {
    if (value == null) {
      return this._value || [];
    }
    if ((0, _isArray.default)(value)) {
      var _context;
      const ParseObject = _CoreManager.default.getParseObject();
      const toAdd = [];
      (0, _forEach.default)(_context = this._value).call(_context, v => {
        if (v instanceof ParseObject) {
          if (!(0, _arrayContainsObject.default)(value, v)) {
            toAdd.push(v);
          }
        } else {
          if ((0, _indexOf.default)(value).call(value, v) < 0) {
            toAdd.push(v);
          }
        }
      });
      return (0, _concat.default)(value).call(value, toAdd);
    }
    throw new Error('Cannot add elements to a non-array value');
  }
  mergeWith(previous) {
    if (!previous) {
      return this;
    }
    if (previous instanceof SetOp) {
      return new SetOp(this.applyTo(previous._value));
    }
    if (previous instanceof UnsetOp) {
      return new SetOp(this._value);
    }
    if (previous instanceof AddUniqueOp) {
      return new AddUniqueOp(this.applyTo(previous._value));
    }
    throw new Error('Cannot merge AddUnique Op with the previous Op');
  }
  toJSON() {
    return {
      __op: 'AddUnique',
      objects: (0, _encode.default)(this._value, false, true)
    };
  }
}
exports.AddUniqueOp = AddUniqueOp;
class RemoveOp extends Op {
  constructor(value) {
    super();
    (0, _defineProperty2.default)(this, "_value", void 0);
    this._value = (0, _unique.default)((0, _isArray.default)(value) ? value : [value]);
  }
  applyTo(value) {
    if (value == null) {
      return [];
    }
    if ((0, _isArray.default)(value)) {
      const ParseObject = _CoreManager.default.getParseObject();
      // var i = value.indexOf(this._value);
      const removed = (0, _concat.default)(value).call(value, []);
      for (let i = 0; i < this._value.length; i++) {
        let index = (0, _indexOf.default)(removed).call(removed, this._value[i]);
        while (index > -1) {
          (0, _splice.default)(removed).call(removed, index, 1);
          index = (0, _indexOf.default)(removed).call(removed, this._value[i]);
        }
        if (this._value[i] instanceof ParseObject && this._value[i].id) {
          for (let j = 0; j < removed.length; j++) {
            if (removed[j] instanceof ParseObject && this._value[i].id === removed[j].id) {
              (0, _splice.default)(removed).call(removed, j, 1);
              j--;
            }
          }
        }
      }
      return removed;
    }
    throw new Error('Cannot remove elements from a non-array value');
  }
  mergeWith(previous) {
    if (!previous) {
      return this;
    }
    if (previous instanceof SetOp) {
      return new SetOp(this.applyTo(previous._value));
    }
    if (previous instanceof UnsetOp) {
      return new UnsetOp();
    }
    if (previous instanceof RemoveOp) {
      var _context2;
      const ParseObject = _CoreManager.default.getParseObject();
      const uniques = (0, _concat.default)(_context2 = previous._value).call(_context2, []);
      for (let i = 0; i < this._value.length; i++) {
        if (this._value[i] instanceof ParseObject) {
          if (!(0, _arrayContainsObject.default)(uniques, this._value[i])) {
            uniques.push(this._value[i]);
          }
        } else {
          if ((0, _indexOf.default)(uniques).call(uniques, this._value[i]) < 0) {
            uniques.push(this._value[i]);
          }
        }
      }
      return new RemoveOp(uniques);
    }
    throw new Error('Cannot merge Remove Op with the previous Op');
  }
  toJSON() {
    return {
      __op: 'Remove',
      objects: (0, _encode.default)(this._value, false, true)
    };
  }
}
exports.RemoveOp = RemoveOp;
class RelationOp extends Op {
  constructor(adds, removes) {
    super();
    (0, _defineProperty2.default)(this, "_targetClassName", void 0);
    (0, _defineProperty2.default)(this, "relationsToAdd", void 0);
    (0, _defineProperty2.default)(this, "relationsToRemove", void 0);
    this._targetClassName = null;
    if ((0, _isArray.default)(adds)) {
      this.relationsToAdd = (0, _unique.default)((0, _map.default)(adds).call(adds, this._extractId, this));
    }
    if ((0, _isArray.default)(removes)) {
      this.relationsToRemove = (0, _unique.default)((0, _map.default)(removes).call(removes, this._extractId, this));
    }
  }
  _extractId(obj) {
    if (typeof obj === 'string') {
      return obj;
    }
    if (!obj.id) {
      throw new Error('You cannot add or remove an unsaved Parse Object from a relation');
    }
    if (!this._targetClassName) {
      this._targetClassName = obj.className;
    }
    if (this._targetClassName !== obj.className) {
      throw new Error('Tried to create a Relation with 2 different object types: ' + this._targetClassName + ' and ' + obj.className + '.');
    }
    return obj.id;
  }
  applyTo(value, parent, key) {
    if (!value) {
      if (!parent || !key) {
        throw new Error('Cannot apply a RelationOp without either a previous value, or an object and a key');
      }
      const relation = new _ParseRelation.default(parent, key);
      relation.targetClassName = this._targetClassName;
      return relation;
    }
    if (value instanceof _ParseRelation.default) {
      if (this._targetClassName) {
        if (value.targetClassName) {
          if (this._targetClassName !== value.targetClassName) {
            throw new Error('Related object must be a ' + value.targetClassName + ', but a ' + this._targetClassName + ' was passed in.');
          }
        } else {
          value.targetClassName = this._targetClassName;
        }
      }
      return value;
    } else {
      throw new Error('Relation cannot be applied to a non-relation field');
    }
  }
  mergeWith(previous) {
    if (!previous) {
      return this;
    } else if (previous instanceof UnsetOp) {
      throw new Error('You cannot modify a relation after deleting it.');
    } else if (previous instanceof SetOp && previous._value instanceof _ParseRelation.default) {
      return this;
    } else if (previous instanceof RelationOp) {
      var _context3, _context4, _context5, _context6, _context7, _context8;
      if (previous._targetClassName && previous._targetClassName !== this._targetClassName) {
        throw new Error('Related object must be of class ' + previous._targetClassName + ', but ' + (this._targetClassName || 'null') + ' was passed in.');
      }
      const newAdd = (0, _concat.default)(_context3 = previous.relationsToAdd).call(_context3, []);
      (0, _forEach.default)(_context4 = this.relationsToRemove).call(_context4, r => {
        const index = (0, _indexOf.default)(newAdd).call(newAdd, r);
        if (index > -1) {
          (0, _splice.default)(newAdd).call(newAdd, index, 1);
        }
      });
      (0, _forEach.default)(_context5 = this.relationsToAdd).call(_context5, r => {
        const index = (0, _indexOf.default)(newAdd).call(newAdd, r);
        if (index < 0) {
          newAdd.push(r);
        }
      });
      const newRemove = (0, _concat.default)(_context6 = previous.relationsToRemove).call(_context6, []);
      (0, _forEach.default)(_context7 = this.relationsToAdd).call(_context7, r => {
        const index = (0, _indexOf.default)(newRemove).call(newRemove, r);
        if (index > -1) {
          (0, _splice.default)(newRemove).call(newRemove, index, 1);
        }
      });
      (0, _forEach.default)(_context8 = this.relationsToRemove).call(_context8, r => {
        const index = (0, _indexOf.default)(newRemove).call(newRemove, r);
        if (index < 0) {
          newRemove.push(r);
        }
      });
      const newRelation = new RelationOp(newAdd, newRemove);
      newRelation._targetClassName = this._targetClassName;
      return newRelation;
    }
    throw new Error('Cannot merge Relation Op with the previous Op');
  }
  toJSON() {
    const idToPointer = id => {
      return {
        __type: 'Pointer',
        className: this._targetClassName,
        objectId: id
      };
    };
    let pointers = null;
    let adds = null;
    let removes = null;
    if (this.relationsToAdd.length > 0) {
      var _context9;
      pointers = (0, _map.default)(_context9 = this.relationsToAdd).call(_context9, idToPointer);
      adds = {
        __op: 'AddRelation',
        objects: pointers
      };
    }
    if (this.relationsToRemove.length > 0) {
      var _context10;
      pointers = (0, _map.default)(_context10 = this.relationsToRemove).call(_context10, idToPointer);
      removes = {
        __op: 'RemoveRelation',
        objects: pointers
      };
    }
    if (adds && removes) {
      return {
        __op: 'Batch',
        ops: [adds, removes]
      };
    }
    return adds || removes || {};
  }
}
exports.RelationOp = RelationOp;
_CoreManager.default.setParseOp({
  Op,
  opFromJSON,
  SetOp,
  UnsetOp,
  IncrementOp,
  AddOp,
  RelationOp,
  RemoveOp,
  AddUniqueOp
});