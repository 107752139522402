"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _stringify = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/json/stringify"));
var _forEach = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/for-each"));
var _map = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/map"));
var _LocalDatastoreUtils = require("./LocalDatastoreUtils");
var _StorageController = _interopRequireDefault(require("./StorageController.react-native"));
const LocalDatastoreController = {
  async fromPinWithName(name) {
    const values = await _StorageController.default.getItemAsync(name);
    if (!values) {
      return [];
    }
    const objects = JSON.parse(values);
    return objects;
  },
  async pinWithName(name, value) {
    try {
      const values = (0, _stringify.default)(value);
      await _StorageController.default.setItemAsync(name, values);
    } catch (e) {
      // Quota exceeded, possibly due to Safari Private Browsing mode
      console.error(e.message);
    }
  },
  unPinWithName(name) {
    return _StorageController.default.removeItemAsync(name);
  },
  async getAllContents() {
    const keys = await _StorageController.default.getAllKeysAsync();
    const batch = [];
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      if ((0, _LocalDatastoreUtils.isLocalDatastoreKey)(key)) {
        batch.push(key);
      }
    }
    const LDS = {};
    let results = [];
    try {
      results = await _StorageController.default.multiGet(batch);
    } catch (error) {
      console.error('Error getAllContents: ', error);
      return {};
    }
    (0, _forEach.default)(results).call(results, pair => {
      const [key, value] = pair;
      try {
        LDS[key] = JSON.parse(value);
      } catch (error) {
        LDS[key] = null;
      }
    });
    return LDS;
  },
  // Used for testing
  async getRawStorage() {
    var _context;
    const keys = await _StorageController.default.getAllKeysAsync();
    const storage = {};
    const results = await _StorageController.default.multiGet(keys);
    (0, _map.default)(_context = results).call(_context, pair => {
      const [key, value] = pair;
      storage[key] = value;
    });
    return storage;
  },
  async clear() {
    const keys = await _StorageController.default.getAllKeysAsync();
    const batch = [];
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      if ((0, _LocalDatastoreUtils.isLocalDatastoreKey)(key)) {
        batch.push(key);
      }
    }
    await _StorageController.default.multiRemove(batch).catch(error => console.error('Error clearing local datastore: ', error));
  }
};
module.exports = LocalDatastoreController;
var _default = exports.default = LocalDatastoreController;