"use strict";

var _keysInstanceProperty = require("@babel/runtime-corejs3/core-js-stable/instance/keys");
var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _idbKeyval = require("idb-keyval");
/* global window */

let IndexedDBStorageController;
if (typeof window !== 'undefined' && window.indexedDB) {
  try {
    const ParseStore = (0, _idbKeyval.createStore)('parseDB', 'parseStore');
    IndexedDBStorageController = {
      async: 1,
      getItemAsync(path) {
        return (0, _idbKeyval.get)(path, ParseStore);
      },
      setItemAsync(path, value) {
        return (0, _idbKeyval.set)(path, value, ParseStore);
      },
      removeItemAsync(path) {
        return (0, _idbKeyval.del)(path, ParseStore);
      },
      getAllKeysAsync() {
        return (0, _keysInstanceProperty(_idbKeyval))(ParseStore);
      },
      clear() {
        return (0, _idbKeyval.clear)(ParseStore);
      }
    };
  } catch (_) {
    // IndexedDB not accessible
    IndexedDBStorageController = undefined;
  }
} else {
  // IndexedDB not supported
  IndexedDBStorageController = undefined;
}
module.exports = IndexedDBStorageController;
var _default = exports.default = IndexedDBStorageController;