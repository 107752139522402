"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _promise = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/promise"));
var _CoreManager = _interopRequireDefault(require("./CoreManager"));
const Storage = {
  async() {
    const controller = _CoreManager.default.getStorageController();
    return !!controller.async;
  },
  getItem(path) {
    const controller = _CoreManager.default.getStorageController();
    if (controller.async === 1) {
      throw new Error('Synchronous storage is not supported by the current storage controller');
    }
    return controller.getItem(path);
  },
  getItemAsync(path) {
    const controller = _CoreManager.default.getStorageController();
    if (controller.async === 1) {
      return controller.getItemAsync(path);
    }
    return _promise.default.resolve(controller.getItem(path));
  },
  setItem(path, value) {
    const controller = _CoreManager.default.getStorageController();
    if (controller.async === 1) {
      throw new Error('Synchronous storage is not supported by the current storage controller');
    }
    return controller.setItem(path, value);
  },
  setItemAsync(path, value) {
    const controller = _CoreManager.default.getStorageController();
    if (controller.async === 1) {
      return controller.setItemAsync(path, value);
    }
    return _promise.default.resolve(controller.setItem(path, value));
  },
  removeItem(path) {
    const controller = _CoreManager.default.getStorageController();
    if (controller.async === 1) {
      throw new Error('Synchronous storage is not supported by the current storage controller');
    }
    return controller.removeItem(path);
  },
  removeItemAsync(path) {
    const controller = _CoreManager.default.getStorageController();
    if (controller.async === 1) {
      return controller.removeItemAsync(path);
    }
    return _promise.default.resolve(controller.removeItem(path));
  },
  getAllKeys() {
    const controller = _CoreManager.default.getStorageController();
    if (controller.async === 1) {
      throw new Error('Synchronous storage is not supported by the current storage controller');
    }
    return controller.getAllKeys();
  },
  getAllKeysAsync() {
    const controller = _CoreManager.default.getStorageController();
    if (controller.async === 1) {
      return controller.getAllKeysAsync();
    }
    return _promise.default.resolve(controller.getAllKeys());
  },
  generatePath(path) {
    if (!_CoreManager.default.get('APPLICATION_ID')) {
      throw new Error('You need to call Parse.initialize before using Parse.');
    }
    if (typeof path !== 'string') {
      throw new Error('Tried to get a Storage path that was not a String.');
    }
    if (path[0] === '/') {
      path = path.substr(1);
    }
    return 'Parse/' + _CoreManager.default.get('APPLICATION_ID') + '/' + path;
  },
  _clear() {
    const controller = _CoreManager.default.getStorageController();
    if (controller.hasOwnProperty('clear')) {
      controller.clear();
    }
  }
};
module.exports = Storage;
var _default = exports.default = Storage;