"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _indexOf = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/index-of"));
var _map = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/map"));
var _keys = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/keys"));
var _stringify = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/json/stringify"));
var _forEach = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/for-each"));
var _values = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/values"));
var _setTimeout2 = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/set-timeout"));
var _bind = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/bind"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/defineProperty"));
var _CoreManager = _interopRequireDefault(require("./CoreManager"));
var _ParseObject = _interopRequireDefault(require("./ParseObject"));
var _LiveQuerySubscription = _interopRequireDefault(require("./LiveQuerySubscription"));
var _promiseUtils = require("./promiseUtils");
var _ParseError = _interopRequireDefault(require("./ParseError"));
// The LiveQuery client inner state
const CLIENT_STATE = {
  INITIALIZED: 'initialized',
  CONNECTING: 'connecting',
  CONNECTED: 'connected',
  CLOSED: 'closed',
  RECONNECTING: 'reconnecting',
  DISCONNECTED: 'disconnected'
};

// The event type the LiveQuery client should sent to server
const OP_TYPES = {
  CONNECT: 'connect',
  SUBSCRIBE: 'subscribe',
  UNSUBSCRIBE: 'unsubscribe',
  ERROR: 'error'
};

// The event we get back from LiveQuery server
const OP_EVENTS = {
  CONNECTED: 'connected',
  SUBSCRIBED: 'subscribed',
  UNSUBSCRIBED: 'unsubscribed',
  ERROR: 'error',
  CREATE: 'create',
  UPDATE: 'update',
  ENTER: 'enter',
  LEAVE: 'leave',
  DELETE: 'delete'
};

// The event the LiveQuery client should emit
const CLIENT_EMMITER_TYPES = {
  CLOSE: 'close',
  ERROR: 'error',
  OPEN: 'open'
};

// The event the LiveQuery subscription should emit
const SUBSCRIPTION_EMMITER_TYPES = {
  OPEN: 'open',
  CLOSE: 'close',
  ERROR: 'error',
  CREATE: 'create',
  UPDATE: 'update',
  ENTER: 'enter',
  LEAVE: 'leave',
  DELETE: 'delete'
};

// Exponentially-growing random delay
const generateInterval = k => {
  return Math.random() * Math.min(30, Math.pow(2, k) - 1) * 1000;
};

/**
 * Creates a new LiveQueryClient.
 * <a href="https://nodejs.org/api/events.html#events_class_eventemitter">cloud functions</a>.
 *
 * A wrapper of a standard WebSocket client. We add several useful methods to
 * help you connect/disconnect to LiveQueryServer, subscribe/unsubscribe a ParseQuery easily.
 *
 * javascriptKey and masterKey are used for verifying the LiveQueryClient when it tries
 * to connect to the LiveQuery server
 *
 * We expose three events to help you monitor the status of the LiveQueryClient.
 *
 * <pre>
 * const LiveQueryClient = Parse.LiveQueryClient;
 * const client = new LiveQueryClient({
 *   applicationId: '',
 *   serverURL: '',
 *   javascriptKey: '',
 *   masterKey: ''
 *  });
 * </pre>
 *
 * Open - When we establish the WebSocket connection to the LiveQuery server, you'll get this event.
 * <pre>
 * client.on('open', () => {
 *
 * });</pre>
 *
 * Close - When we lose the WebSocket connection to the LiveQuery server, you'll get this event.
 * <pre>
 * client.on('close', () => {
 *
 * });</pre>
 *
 * Error - When some network error or LiveQuery server error happens, you'll get this event.
 * <pre>
 * client.on('error', (error) => {
 *
 * });</pre>
 *
 * @alias Parse.LiveQueryClient
 */
class LiveQueryClient {
  /**
   * @param {object} options
   * @param {string} options.applicationId - applicationId of your Parse app
   * @param {string} options.serverURL - <b>the URL of your LiveQuery server</b>
   * @param {string} options.javascriptKey (optional)
   * @param {string} options.masterKey (optional) Your Parse Master Key. (Node.js only!)
   * @param {string} options.sessionToken (optional)
   * @param {string} options.installationId (optional)
   */
  constructor(_ref) {
    var _this = this;
    let {
      applicationId,
      serverURL,
      javascriptKey,
      masterKey,
      sessionToken,
      installationId
    } = _ref;
    (0, _defineProperty2.default)(this, "attempts", void 0);
    (0, _defineProperty2.default)(this, "id", void 0);
    (0, _defineProperty2.default)(this, "requestId", void 0);
    (0, _defineProperty2.default)(this, "applicationId", void 0);
    (0, _defineProperty2.default)(this, "serverURL", void 0);
    (0, _defineProperty2.default)(this, "javascriptKey", void 0);
    (0, _defineProperty2.default)(this, "masterKey", void 0);
    (0, _defineProperty2.default)(this, "sessionToken", void 0);
    (0, _defineProperty2.default)(this, "installationId", void 0);
    (0, _defineProperty2.default)(this, "additionalProperties", void 0);
    (0, _defineProperty2.default)(this, "connectPromise", void 0);
    (0, _defineProperty2.default)(this, "subscriptions", void 0);
    (0, _defineProperty2.default)(this, "socket", void 0);
    (0, _defineProperty2.default)(this, "state", void 0);
    (0, _defineProperty2.default)(this, "reconnectHandle", void 0);
    (0, _defineProperty2.default)(this, "emitter", void 0);
    (0, _defineProperty2.default)(this, "on", void 0);
    (0, _defineProperty2.default)(this, "emit", void 0);
    if (!serverURL || (0, _indexOf.default)(serverURL).call(serverURL, 'ws') !== 0) {
      throw new Error('You need to set a proper Parse LiveQuery server url before using LiveQueryClient');
    }
    this.reconnectHandle = null;
    this.attempts = 1;
    this.id = 0;
    this.requestId = 1;
    this.serverURL = serverURL;
    this.applicationId = applicationId;
    this.javascriptKey = javascriptKey || undefined;
    this.masterKey = masterKey || undefined;
    this.sessionToken = sessionToken || undefined;
    this.installationId = installationId || undefined;
    this.additionalProperties = true;
    this.connectPromise = (0, _promiseUtils.resolvingPromise)();
    this.subscriptions = new _map.default();
    this.state = CLIENT_STATE.INITIALIZED;
    const EventEmitter = _CoreManager.default.getEventEmitter();
    this.emitter = new EventEmitter();
    this.on = (eventName, listener) => this.emitter.on(eventName, listener);
    this.emit = function (eventName) {
      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }
      return _this.emitter.emit(eventName, ...args);
    };
    // adding listener so process does not crash
    // best practice is for developer to register their own listener
    this.on('error', () => {});
  }
  shouldOpen() {
    return this.state === CLIENT_STATE.INITIALIZED || this.state === CLIENT_STATE.DISCONNECTED;
  }

  /**
   * Subscribes to a ParseQuery
   *
   * If you provide the sessionToken, when the LiveQuery server gets ParseObject's
   * updates from parse server, it'll try to check whether the sessionToken fulfills
   * the ParseObject's ACL. The LiveQuery server will only send updates to clients whose
   * sessionToken is fit for the ParseObject's ACL. You can check the LiveQuery protocol
   * <a href="https://github.com/parse-community/parse-server/wiki/Parse-LiveQuery-Protocol-Specification">here</a> for more details. The subscription you get is the same subscription you get
   * from our Standard API.
   *
   * @param {ParseQuery} query - the ParseQuery you want to subscribe to
   * @param {string} sessionToken (optional)
   * @returns {LiveQuerySubscription | undefined}
   */
  subscribe(query, sessionToken) {
    if (!query) {
      return;
    }
    const className = query.className;
    const queryJSON = query.toJSON();
    const where = queryJSON.where;
    const keys = (0, _keys.default)(queryJSON)?.split(',');
    const watch = queryJSON.watch?.split(',');
    const subscribeRequest = {
      op: OP_TYPES.SUBSCRIBE,
      requestId: this.requestId,
      query: {
        className,
        where,
        keys,
        watch
      },
      sessionToken: undefined
    };
    if (sessionToken) {
      subscribeRequest.sessionToken = sessionToken;
    }
    const subscription = new _LiveQuerySubscription.default(this.requestId, query, sessionToken);
    this.subscriptions.set(this.requestId, subscription);
    this.requestId += 1;
    this.connectPromise.then(() => {
      this.socket.send((0, _stringify.default)(subscribeRequest));
    }).catch(error => {
      subscription.subscribePromise.reject(error);
    });
    return subscription;
  }

  /**
   * After calling unsubscribe you'll stop receiving events from the subscription object.
   *
   * @param {object} subscription - subscription you would like to unsubscribe from.
   * @returns {Promise | undefined}
   */
  async unsubscribe(subscription) {
    if (!subscription) {
      return;
    }
    const unsubscribeRequest = {
      op: OP_TYPES.UNSUBSCRIBE,
      requestId: subscription.id
    };
    return this.connectPromise.then(() => {
      return this.socket.send((0, _stringify.default)(unsubscribeRequest));
    }).then(() => {
      return subscription.unsubscribePromise;
    });
  }

  /**
   * After open is called, the LiveQueryClient will try to send a connect request
   * to the LiveQuery server.
   *
   */
  open() {
    const WebSocketImplementation = _CoreManager.default.getWebSocketController();
    if (!WebSocketImplementation) {
      this.emit(CLIENT_EMMITER_TYPES.ERROR, 'Can not find WebSocket implementation');
      return;
    }
    if (this.state !== CLIENT_STATE.RECONNECTING) {
      this.state = CLIENT_STATE.CONNECTING;
    }
    this.socket = new WebSocketImplementation(this.serverURL);
    this.socket.closingPromise = (0, _promiseUtils.resolvingPromise)();

    // Bind WebSocket callbacks
    this.socket.onopen = () => {
      this._handleWebSocketOpen();
    };
    this.socket.onmessage = event => {
      this._handleWebSocketMessage(event);
    };
    this.socket.onclose = event => {
      this.socket.closingPromise?.resolve(event);
      this._handleWebSocketClose();
    };
    this.socket.onerror = error => {
      this._handleWebSocketError(error);
    };
  }
  resubscribe() {
    var _context;
    (0, _forEach.default)(_context = this.subscriptions).call(_context, (subscription, requestId) => {
      const query = subscription.query;
      const queryJSON = query.toJSON();
      const where = queryJSON.where;
      const keys = (0, _keys.default)(queryJSON)?.split(',');
      const watch = queryJSON.watch?.split(',');
      const className = query.className;
      const sessionToken = subscription.sessionToken;
      const subscribeRequest = {
        op: OP_TYPES.SUBSCRIBE,
        requestId,
        query: {
          className,
          where,
          keys,
          watch
        },
        sessionToken: undefined
      };
      if (sessionToken) {
        subscribeRequest.sessionToken = sessionToken;
      }
      this.connectPromise.then(() => {
        this.socket.send((0, _stringify.default)(subscribeRequest));
      });
    });
  }

  /**
   * This method will close the WebSocket connection to this LiveQueryClient,
   * cancel the auto reconnect and unsubscribe all subscriptions based on it.
   *
   * @returns {Promise | undefined} CloseEvent {@link https://developer.mozilla.org/en-US/docs/Web/API/WebSocket/close_event}
   */
  async close() {
    if (this.state === CLIENT_STATE.INITIALIZED || this.state === CLIENT_STATE.DISCONNECTED) {
      return;
    }
    this.state = CLIENT_STATE.DISCONNECTED;
    this.socket?.close();
    // Notify each subscription about the close
    for (const subscription of (0, _values.default)(_context2 = this.subscriptions).call(_context2)) {
      var _context2;
      subscription.subscribed = false;
      subscription.emit(SUBSCRIPTION_EMMITER_TYPES.CLOSE);
    }
    this._handleReset();
    this.emit(CLIENT_EMMITER_TYPES.CLOSE);
    return this.socket?.closingPromise;
  }

  // ensure we start with valid state if connect is called again after close
  _handleReset() {
    this.attempts = 1;
    this.id = 0;
    this.requestId = 1;
    this.connectPromise = (0, _promiseUtils.resolvingPromise)();
    this.subscriptions = new _map.default();
  }
  _handleWebSocketOpen() {
    const connectRequest = {
      op: OP_TYPES.CONNECT,
      applicationId: this.applicationId,
      javascriptKey: this.javascriptKey,
      masterKey: this.masterKey,
      sessionToken: this.sessionToken,
      installationId: undefined
    };
    if (this.additionalProperties) {
      connectRequest.installationId = this.installationId;
    }
    this.socket.send((0, _stringify.default)(connectRequest));
  }
  _handleWebSocketMessage(event) {
    let data = event.data;
    if (typeof data === 'string') {
      data = JSON.parse(data);
    }
    let subscription = null;
    if (data.requestId) {
      subscription = this.subscriptions.get(data.requestId) || null;
    }
    const response = {
      clientId: data.clientId,
      installationId: data.installationId
    };
    switch (data.op) {
      case OP_EVENTS.CONNECTED:
        if (this.state === CLIENT_STATE.RECONNECTING) {
          this.resubscribe();
        }
        this.emit(CLIENT_EMMITER_TYPES.OPEN);
        this.id = data.clientId;
        this.connectPromise.resolve();
        this.state = CLIENT_STATE.CONNECTED;
        break;
      case OP_EVENTS.SUBSCRIBED:
        if (subscription) {
          this.attempts = 1;
          subscription.subscribed = true;
          subscription.subscribePromise.resolve();
          (0, _setTimeout2.default)(() => subscription.emit(SUBSCRIPTION_EMMITER_TYPES.OPEN, response), 200);
        }
        break;
      case OP_EVENTS.ERROR:
        {
          const parseError = new _ParseError.default(data.code, data.error);
          if (!this.id) {
            this.connectPromise.reject(parseError);
            this.state = CLIENT_STATE.DISCONNECTED;
          }
          if (data.requestId) {
            if (subscription) {
              subscription.subscribePromise.reject(parseError);
              (0, _setTimeout2.default)(() => subscription.emit(SUBSCRIPTION_EMMITER_TYPES.ERROR, data.error), 200);
            }
          } else {
            this.emit(CLIENT_EMMITER_TYPES.ERROR, data.error);
          }
          if (data.error === 'Additional properties not allowed') {
            this.additionalProperties = false;
          }
          if (data.reconnect) {
            this._handleReconnect();
          }
          break;
        }
      case OP_EVENTS.UNSUBSCRIBED:
        {
          if (subscription) {
            this.subscriptions.delete(data.requestId);
            subscription.subscribed = false;
            subscription.unsubscribePromise.resolve();
          }
          break;
        }
      default:
        {
          // create, update, enter, leave, delete cases
          if (!subscription) {
            break;
          }
          let override = false;
          if (data.original) {
            override = true;
            delete data.original.__type;
            // Check for removed fields
            for (const field in data.original) {
              if (!(field in data.object)) {
                data.object[field] = undefined;
              }
            }
            data.original = _ParseObject.default.fromJSON(data.original, false);
          }
          delete data.object.__type;
          const parseObject = _ParseObject.default.fromJSON(data.object, !(subscription.query && subscription.query._select) ? override : false);
          if (data.original) {
            subscription.emit(data.op, parseObject, data.original, response);
          } else {
            subscription.emit(data.op, parseObject, response);
          }
          const localDatastore = _CoreManager.default.getLocalDatastore();
          if (override && localDatastore.isEnabled) {
            localDatastore._updateObjectIfPinned(parseObject).then(() => {});
          }
        }
    }
  }
  _handleWebSocketClose() {
    if (this.state === CLIENT_STATE.DISCONNECTED) {
      return;
    }
    this.state = CLIENT_STATE.CLOSED;
    this.emit(CLIENT_EMMITER_TYPES.CLOSE);
    // Notify each subscription about the close
    for (const subscription of (0, _values.default)(_context3 = this.subscriptions).call(_context3)) {
      var _context3;
      subscription.emit(SUBSCRIPTION_EMMITER_TYPES.CLOSE);
    }
    this._handleReconnect();
  }
  _handleWebSocketError(error) {
    this.emit(CLIENT_EMMITER_TYPES.ERROR, error);
    for (const subscription of (0, _values.default)(_context4 = this.subscriptions).call(_context4)) {
      var _context4;
      subscription.emit(SUBSCRIPTION_EMMITER_TYPES.ERROR, error);
    }
    this._handleReconnect();
  }
  _handleReconnect() {
    var _context5;
    // if closed or currently reconnecting we stop attempting to reconnect
    if (this.state === CLIENT_STATE.DISCONNECTED) {
      return;
    }
    this.state = CLIENT_STATE.RECONNECTING;
    const time = generateInterval(this.attempts);

    // handle case when both close/error occur at frequent rates we ensure we do not reconnect unnecessarily.
    // we're unable to distinguish different between close/error when we're unable to reconnect therefore
    // we try to reconnect in both cases
    // server side ws and browser WebSocket behave differently in when close/error get triggered
    if (this.reconnectHandle) {
      clearTimeout(this.reconnectHandle);
    }
    this.reconnectHandle = (0, _setTimeout2.default)((0, _bind.default)(_context5 = () => {
      this.attempts++;
      this.connectPromise = (0, _promiseUtils.resolvingPromise)();
      this.open();
    }).call(_context5, this), time);
  }
}
var _default = exports.default = LiveQueryClient;