"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _stringify = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/json/stringify"));
var _CoreManager = _interopRequireDefault(require("./CoreManager"));
var _Storage = _interopRequireDefault(require("./Storage"));
var _ParseInstallation = _interopRequireDefault(require("./ParseInstallation"));
var _uuid = _interopRequireDefault(require("./uuid"));
const CURRENT_INSTALLATION_KEY = 'currentInstallation';
const CURRENT_INSTALLATION_ID_KEY = 'currentInstallationId';
let iidCache = null;
let currentInstallationCache = null;
let currentInstallationCacheMatchesDisk = false;
const InstallationController = {
  async updateInstallationOnDisk(installation) {
    const path = _Storage.default.generatePath(CURRENT_INSTALLATION_KEY);
    await _Storage.default.setItemAsync(path, (0, _stringify.default)(installation.toJSON()));
    this._setCurrentInstallationCache(installation);
  },
  async currentInstallationId() {
    if (typeof iidCache === 'string') {
      return iidCache;
    }
    const path = _Storage.default.generatePath(CURRENT_INSTALLATION_ID_KEY);
    let iid = await _Storage.default.getItemAsync(path);
    if (!iid) {
      iid = (0, _uuid.default)();
      return _Storage.default.setItemAsync(path, iid).then(() => {
        iidCache = iid;
        return iid;
      });
    }
    iidCache = iid;
    return iid;
  },
  async currentInstallation() {
    if (currentInstallationCache) {
      return currentInstallationCache;
    }
    if (currentInstallationCacheMatchesDisk) {
      return null;
    }
    const path = _Storage.default.generatePath(CURRENT_INSTALLATION_KEY);
    let installationData = await _Storage.default.getItemAsync(path);
    currentInstallationCacheMatchesDisk = true;
    if (installationData) {
      installationData = JSON.parse(installationData);
      installationData.className = '_Installation';
      const current = _ParseInstallation.default.fromJSON(installationData);
      currentInstallationCache = current;
      return current;
    }
    const installationId = await this.currentInstallationId();
    const installation = new _ParseInstallation.default();
    installation.set('deviceType', _ParseInstallation.default.DEVICE_TYPES.WEB);
    installation.set('installationId', installationId);
    installation.set('parseVersion', _CoreManager.default.get('VERSION'));
    currentInstallationCache = installation;
    await _Storage.default.setItemAsync(path, (0, _stringify.default)(installation.toJSON()));
    return installation;
  },
  _clearCache() {
    iidCache = null;
    currentInstallationCache = null;
    currentInstallationCacheMatchesDisk = false;
  },
  _setInstallationIdCache(iid) {
    iidCache = iid;
  },
  _setCurrentInstallationCache(installation) {
    let matchesDisk = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    currentInstallationCache = installation;
    currentInstallationCacheMatchesDisk = matchesDisk;
  }
};
module.exports = InstallationController;
var _default = exports.default = InstallationController;