"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _stringify = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/json/stringify"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/defineProperty"));
class XhrWeapp {
  constructor() {
    (0, _defineProperty2.default)(this, "UNSENT", void 0);
    (0, _defineProperty2.default)(this, "OPENED", void 0);
    (0, _defineProperty2.default)(this, "HEADERS_RECEIVED", void 0);
    (0, _defineProperty2.default)(this, "LOADING", void 0);
    (0, _defineProperty2.default)(this, "DONE", void 0);
    (0, _defineProperty2.default)(this, "header", void 0);
    (0, _defineProperty2.default)(this, "readyState", void 0);
    (0, _defineProperty2.default)(this, "status", void 0);
    (0, _defineProperty2.default)(this, "response", void 0);
    (0, _defineProperty2.default)(this, "responseType", void 0);
    (0, _defineProperty2.default)(this, "responseText", void 0);
    (0, _defineProperty2.default)(this, "responseHeader", void 0);
    (0, _defineProperty2.default)(this, "method", void 0);
    (0, _defineProperty2.default)(this, "url", void 0);
    (0, _defineProperty2.default)(this, "onabort", void 0);
    (0, _defineProperty2.default)(this, "onprogress", void 0);
    (0, _defineProperty2.default)(this, "onerror", void 0);
    (0, _defineProperty2.default)(this, "onreadystatechange", void 0);
    (0, _defineProperty2.default)(this, "requestTask", void 0);
    this.UNSENT = 0;
    this.OPENED = 1;
    this.HEADERS_RECEIVED = 2;
    this.LOADING = 3;
    this.DONE = 4;
    this.header = {};
    this.readyState = this.DONE;
    this.status = 0;
    this.response = '';
    this.responseType = '';
    this.responseText = '';
    this.responseHeader = {};
    this.method = '';
    this.url = '';
    this.onabort = () => {};
    this.onprogress = () => {};
    this.onerror = () => {};
    this.onreadystatechange = () => {};
    this.requestTask = null;
  }
  getAllResponseHeaders() {
    let header = '';
    for (const key in this.responseHeader) {
      header += key + ':' + this.getResponseHeader(key) + '\r\n';
    }
    return header;
  }
  getResponseHeader(key) {
    return this.responseHeader[key];
  }
  setRequestHeader(key, value) {
    this.header[key] = value;
  }
  open(method, url) {
    this.method = method;
    this.url = url;
  }
  abort() {
    if (!this.requestTask) {
      return;
    }
    this.requestTask.abort();
    this.status = 0;
    this.response = undefined;
    this.onabort();
    this.onreadystatechange();
  }
  send(data) {
    // @ts-ignore
    this.requestTask = wx.request({
      url: this.url,
      method: this.method,
      data: data,
      header: this.header,
      responseType: this.responseType,
      success: res => {
        this.status = res.statusCode;
        this.response = res.data;
        this.responseHeader = res.header;
        this.responseText = (0, _stringify.default)(res.data);
        this.requestTask = null;
        this.onreadystatechange();
      },
      fail: err => {
        this.requestTask = null;
        // @ts-ignore
        this.onerror(err);
      }
    });
    this.requestTask.onProgressUpdate(res => {
      const event = {
        lengthComputable: res.totalBytesExpectedToWrite !== 0,
        loaded: res.totalBytesWritten,
        total: res.totalBytesExpectedToWrite
      };
      // @ts-ignore
      this.onprogress(event);
    });
  }
}
module.exports = XhrWeapp;
var _default = exports.default = XhrWeapp;