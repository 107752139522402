"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.default = arrayContainsObject;
var _indexOf = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/index-of"));
var _CoreManager = _interopRequireDefault(require("./CoreManager"));
function arrayContainsObject(array, object) {
  if ((0, _indexOf.default)(array).call(array, object) > -1) {
    return true;
  }
  const ParseObject = _CoreManager.default.getParseObject();
  for (let i = 0; i < array.length; i++) {
    if (array[i] instanceof ParseObject && array[i].className === object.className && array[i]._getId() === object._getId()) {
      return true;
    }
  }
  return false;
}