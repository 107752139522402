"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _promise = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/promise"));
var _CoreManager = _interopRequireDefault(require("./CoreManager"));
const StorageController = {
  async: 1,
  getItemAsync(path) {
    return new _promise.default((resolve, reject) => {
      _CoreManager.default.getAsyncStorage().getItem(path, (err, value) => {
        if (err) {
          reject(err);
        } else {
          resolve(value || null);
        }
      });
    });
  },
  setItemAsync(path, value) {
    return new _promise.default((resolve, reject) => {
      _CoreManager.default.getAsyncStorage().setItem(path, value, err => {
        if (err) {
          reject(err);
        } else {
          resolve();
        }
      });
    });
  },
  removeItemAsync(path) {
    return new _promise.default((resolve, reject) => {
      _CoreManager.default.getAsyncStorage().removeItem(path, err => {
        if (err) {
          reject(err);
        } else {
          resolve();
        }
      });
    });
  },
  getAllKeysAsync() {
    return new _promise.default((resolve, reject) => {
      _CoreManager.default.getAsyncStorage().getAllKeys((err, keys) => {
        if (err) {
          reject(err);
        } else {
          resolve(keys || []);
        }
      });
    });
  },
  multiGet(keys) {
    return new _promise.default((resolve, reject) => {
      _CoreManager.default.getAsyncStorage().multiGet(keys, (err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve(result || null);
        }
      });
    });
  },
  multiRemove(keys) {
    return new _promise.default((resolve, reject) => {
      _CoreManager.default.getAsyncStorage().multiRemove(keys, err => {
        if (err) {
          reject(err);
        } else {
          resolve(keys);
        }
      });
    });
  },
  clear() {
    return _CoreManager.default.getAsyncStorage().clear();
  }
};
module.exports = StorageController;
var _default = exports.default = StorageController;