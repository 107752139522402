"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _forEach = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/for-each"));
/** Based on https://github.com/react-native-async-storage/async-storage/blob/main/packages/default-storage-backend/src/types.ts */

const config = {
  IS_NODE: typeof process !== 'undefined' && !!process.versions && !!process.versions.node && !process.versions.electron,
  REQUEST_ATTEMPT_LIMIT: 5,
  REQUEST_BATCH_SIZE: 20,
  REQUEST_HEADERS: {},
  SERVER_URL: 'https://api.parse.com/1',
  SERVER_AUTH_TYPE: null,
  SERVER_AUTH_TOKEN: null,
  LIVEQUERY_SERVER_URL: null,
  ENCRYPTED_KEY: null,
  VERSION: 'js' + "5.3.0",
  APPLICATION_ID: null,
  JAVASCRIPT_KEY: null,
  MASTER_KEY: null,
  USE_MASTER_KEY: false,
  PERFORM_USER_REWRITE: true,
  FORCE_REVOCABLE_SESSION: false,
  ENCRYPTED_USER: false,
  IDEMPOTENCY: false,
  ALLOW_CUSTOM_OBJECT_ID: false,
  PARSE_ERRORS: []
};
function requireMethods(name, methods, controller) {
  (0, _forEach.default)(methods).call(methods, func => {
    if (typeof controller[func] !== 'function') {
      throw new Error(`${name} must implement ${func}()`);
    }
  });
}
const CoreManager = {
  get: function (key) {
    if (config.hasOwnProperty(key)) {
      return config[key];
    }
    throw new Error('Configuration key not found: ' + key);
  },
  set: function (key, value) {
    config[key] = value;
  },
  setIfNeeded: function (key, value) {
    if (!config.hasOwnProperty(key)) {
      config[key] = value;
    }
    return config[key];
  },
  /* Specialized Controller Setters/Getters */

  setAnalyticsController(controller) {
    requireMethods('AnalyticsController', ['track'], controller);
    config['AnalyticsController'] = controller;
  },
  getAnalyticsController() {
    return config['AnalyticsController'];
  },
  setCloudController(controller) {
    requireMethods('CloudController', ['run', 'getJobsData', 'startJob'], controller);
    config['CloudController'] = controller;
  },
  getCloudController() {
    return config['CloudController'];
  },
  setConfigController(controller) {
    requireMethods('ConfigController', ['current', 'get', 'save'], controller);
    config['ConfigController'] = controller;
  },
  getConfigController() {
    return config['ConfigController'];
  },
  setCryptoController(controller) {
    requireMethods('CryptoController', ['encrypt', 'decrypt'], controller);
    config['CryptoController'] = controller;
  },
  getCryptoController() {
    return config['CryptoController'];
  },
  setEventEmitter(eventEmitter) {
    config['EventEmitter'] = eventEmitter;
  },
  getEventEmitter() {
    return config['EventEmitter'];
  },
  setFileController(controller) {
    requireMethods('FileController', ['saveFile', 'saveBase64'], controller);
    config['FileController'] = controller;
  },
  setEventuallyQueue(controller) {
    requireMethods('EventuallyQueue', ['poll', 'save', 'destroy'], controller);
    config['EventuallyQueue'] = controller;
  },
  getEventuallyQueue() {
    return config['EventuallyQueue'];
  },
  getFileController() {
    return config['FileController'];
  },
  setInstallationController(controller) {
    requireMethods('InstallationController', ['currentInstallationId', 'currentInstallation', 'updateInstallationOnDisk'], controller);
    config['InstallationController'] = controller;
  },
  getInstallationController() {
    return config['InstallationController'];
  },
  setLiveQuery(liveQuery) {
    config['LiveQuery'] = liveQuery;
  },
  getLiveQuery() {
    return config['LiveQuery'];
  },
  setObjectController(controller) {
    requireMethods('ObjectController', ['save', 'fetch', 'destroy'], controller);
    config['ObjectController'] = controller;
  },
  getObjectController() {
    return config['ObjectController'];
  },
  setObjectStateController(controller) {
    requireMethods('ObjectStateController', ['getState', 'initializeState', 'removeState', 'getServerData', 'setServerData', 'getPendingOps', 'setPendingOp', 'pushPendingState', 'popPendingState', 'mergeFirstPendingState', 'getObjectCache', 'estimateAttribute', 'estimateAttributes', 'commitServerChanges', 'enqueueTask', 'clearAllState'], controller);
    config['ObjectStateController'] = controller;
  },
  getObjectStateController() {
    return config['ObjectStateController'];
  },
  setPushController(controller) {
    requireMethods('PushController', ['send'], controller);
    config['PushController'] = controller;
  },
  getPushController() {
    return config['PushController'];
  },
  setQueryController(controller) {
    requireMethods('QueryController', ['find', 'aggregate'], controller);
    config['QueryController'] = controller;
  },
  getQueryController() {
    return config['QueryController'];
  },
  setRESTController(controller) {
    requireMethods('RESTController', ['request', 'ajax'], controller);
    config['RESTController'] = controller;
  },
  getRESTController() {
    return config['RESTController'];
  },
  setSchemaController(controller) {
    requireMethods('SchemaController', ['get', 'create', 'update', 'delete', 'send', 'purge'], controller);
    config['SchemaController'] = controller;
  },
  getSchemaController() {
    return config['SchemaController'];
  },
  setSessionController(controller) {
    requireMethods('SessionController', ['getSession'], controller);
    config['SessionController'] = controller;
  },
  getSessionController() {
    return config['SessionController'];
  },
  setStorageController(controller) {
    if (controller.async) {
      requireMethods('An async StorageController', ['getItemAsync', 'setItemAsync', 'removeItemAsync', 'getAllKeysAsync'], controller);
    } else {
      requireMethods('A synchronous StorageController', ['getItem', 'setItem', 'removeItem', 'getAllKeys'], controller);
    }
    config['StorageController'] = controller;
  },
  setLocalDatastoreController(controller) {
    requireMethods('LocalDatastoreController', ['pinWithName', 'fromPinWithName', 'unPinWithName', 'getAllContents', 'clear'], controller);
    config['LocalDatastoreController'] = controller;
  },
  getLocalDatastoreController() {
    return config['LocalDatastoreController'];
  },
  setLocalDatastore(store) {
    config['LocalDatastore'] = store;
  },
  getLocalDatastore() {
    return config['LocalDatastore'];
  },
  getStorageController() {
    return config['StorageController'];
  },
  setAsyncStorage(storage) {
    config['AsyncStorage'] = storage;
  },
  getAsyncStorage() {
    return config['AsyncStorage'];
  },
  setWebSocketController(controller) {
    config['WebSocketController'] = controller;
  },
  getWebSocketController() {
    return config['WebSocketController'];
  },
  setUserController(controller) {
    requireMethods('UserController', ['setCurrentUser', 'currentUser', 'currentUserAsync', 'signUp', 'logIn', 'become', 'logOut', 'me', 'requestPasswordReset', 'upgradeToRevocableSession', 'requestEmailVerification', 'verifyPassword', 'linkWith'], controller);
    config['UserController'] = controller;
  },
  getUserController() {
    return config['UserController'];
  },
  setLiveQueryController(controller) {
    requireMethods('LiveQueryController', ['setDefaultLiveQueryClient', 'getDefaultLiveQueryClient', '_clearCachedDefaultClient'], controller);
    config['LiveQueryController'] = controller;
  },
  getLiveQueryController() {
    return config['LiveQueryController'];
  },
  setHooksController(controller) {
    requireMethods('HooksController', ['create', 'get', 'update', 'remove'], controller);
    config['HooksController'] = controller;
  },
  getHooksController() {
    return config['HooksController'];
  },
  setParseOp(op) {
    config['ParseOp'] = op;
  },
  getParseOp() {
    return config['ParseOp'];
  },
  setParseObject(object) {
    config['ParseObject'] = object;
  },
  getParseObject() {
    return config['ParseObject'];
  },
  setParseQuery(query) {
    config['ParseQuery'] = query;
  },
  getParseQuery() {
    return config['ParseQuery'];
  },
  setParseRole(role) {
    config['ParseRole'] = role;
  },
  getParseRole() {
    return config['ParseRole'];
  },
  setParseUser(user) {
    config['ParseUser'] = user;
  },
  getParseUser() {
    return config['ParseUser'];
  }
};
module.exports = CoreManager;
var _default = exports.default = CoreManager;