"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/defineProperty"));
class SocketWeapp {
  constructor(serverURL) {
    (0, _defineProperty2.default)(this, "onopen", void 0);
    (0, _defineProperty2.default)(this, "onmessage", void 0);
    (0, _defineProperty2.default)(this, "onclose", void 0);
    (0, _defineProperty2.default)(this, "onerror", void 0);
    this.onopen = () => {};
    this.onmessage = () => {};
    this.onclose = () => {};
    this.onerror = () => {};

    // @ts-ignore
    wx.onSocketOpen(() => {
      this.onopen();
    });

    // @ts-ignore
    wx.onSocketMessage(msg => {
      // @ts-ignore
      this.onmessage(msg);
    });

    // @ts-ignore
    wx.onSocketClose(event => {
      // @ts-ignore
      this.onclose(event);
    });

    // @ts-ignore
    wx.onSocketError(error => {
      // @ts-ignore
      this.onerror(error);
    });

    // @ts-ignore
    wx.connectSocket({
      url: serverURL
    });
  }
  send(data) {
    // @ts-ignore
    wx.sendSocketMessage({
      data
    });
  }
  close() {
    // @ts-ignore
    wx.closeSocket();
  }
}
module.exports = SocketWeapp;
var _default = exports.default = SocketWeapp;