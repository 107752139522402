"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _keys = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/keys"));
// When there is no native storage interface, we default to an in-memory map
const memMap = {};
const StorageController = {
  async: 0,
  getItem(path) {
    if (memMap.hasOwnProperty(path)) {
      return memMap[path];
    }
    return null;
  },
  setItem(path, value) {
    memMap[path] = String(value);
  },
  removeItem(path) {
    delete memMap[path];
  },
  getAllKeys() {
    return (0, _keys.default)(memMap);
  },
  clear() {
    for (const key in memMap) {
      if (memMap.hasOwnProperty(key)) {
        delete memMap[key];
      }
    }
  }
};
module.exports = StorageController;
var _default = exports.default = StorageController;