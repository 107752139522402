"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/defineProperty"));
var _promiseUtils = require("./promiseUtils");
class TaskQueue {
  constructor() {
    (0, _defineProperty2.default)(this, "queue", void 0);
    this.queue = [];
  }
  enqueue(task) {
    const taskComplete = (0, _promiseUtils.resolvingPromise)();
    this.queue.push({
      task: task,
      _completion: taskComplete
    });
    if (this.queue.length === 1) {
      task().then(() => {
        this._dequeue();
        taskComplete.resolve();
      }, error => {
        this._dequeue();
        taskComplete.reject(error);
      });
    }
    return taskComplete;
  }
  _dequeue() {
    this.queue.shift();
    if (this.queue.length) {
      const next = this.queue[0];
      next.task().then(() => {
        this._dequeue();
        next._completion.resolve();
      }, error => {
        this._dequeue();
        next._completion.reject(error);
      });
    }
  }
}
module.exports = TaskQueue;
var _default = exports.default = TaskQueue;