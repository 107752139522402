"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _indexOf = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/index-of"));
var _keys = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/keys"));
var _startsWith = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/starts-with"));
var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));
var _isArray = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/array/is-array"));
var _map = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/map"));
var _CoreManager = _interopRequireDefault(require("./CoreManager"));
var _ParseACL = _interopRequireDefault(require("./ParseACL"));
var _ParseFile = _interopRequireDefault(require("./ParseFile"));
var _ParseGeoPoint = _interopRequireDefault(require("./ParseGeoPoint"));
var _ParsePolygon = _interopRequireDefault(require("./ParsePolygon"));
var _ParseRelation = _interopRequireDefault(require("./ParseRelation"));
function encode(value, disallowObjects, forcePointers, seen, offline) {
  const ParseObject = _CoreManager.default.getParseObject();
  if (value instanceof ParseObject) {
    if (disallowObjects) {
      throw new Error('Parse Objects not allowed here');
    }
    const seenEntry = value.id ? value.className + ':' + value.id : value;
    if (forcePointers || !seen || (0, _indexOf.default)(seen).call(seen, seenEntry) > -1 || value.dirty() || (0, _keys.default)(value._getServerData()).length < 1) {
      var _context;
      if (offline && (0, _startsWith.default)(_context = value._getId()).call(_context, 'local')) {
        return value.toOfflinePointer();
      }
      return value.toPointer();
    }
    seen = (0, _concat.default)(seen).call(seen, seenEntry);
    return value._toFullJSON(seen, offline);
  }
  const {
    Op
  } = _CoreManager.default.getParseOp();
  if (value instanceof Op || value instanceof _ParseACL.default || value instanceof _ParseGeoPoint.default || value instanceof _ParsePolygon.default || value instanceof _ParseRelation.default) {
    return value.toJSON();
  }
  if (value instanceof _ParseFile.default) {
    if (!value.url()) {
      throw new Error('Tried to encode an unsaved file.');
    }
    return value.toJSON();
  }
  if (Object.prototype.toString.call(value) === '[object Date]') {
    if (isNaN(value)) {
      throw new Error('Tried to encode an invalid date.');
    }
    return {
      __type: 'Date',
      iso: value.toJSON()
    };
  }
  if (Object.prototype.toString.call(value) === '[object RegExp]' && typeof value.source === 'string') {
    return value.source;
  }
  if ((0, _isArray.default)(value)) {
    return (0, _map.default)(value).call(value, v => {
      return encode(v, disallowObjects, forcePointers, seen, offline);
    });
  }
  if (value && typeof value === 'object') {
    const output = {};
    for (const k in value) {
      output[k] = encode(value[k], disallowObjects, forcePointers, seen, offline);
    }
    return output;
  }
  return value;
}
function _default(value, disallowObjects, forcePointers, seen, offline) {
  return encode(value, !!disallowObjects, !!forcePointers, seen || [], offline);
}