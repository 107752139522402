"use strict";

var _Object$defineProperty2 = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
_Object$defineProperty2(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _promise = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/promise"));
var _defineProperty = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/define-property"));
var _stringify = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/json/stringify"));
var _CoreManager = _interopRequireDefault(require("./CoreManager"));
var _isRevocableSession = _interopRequireDefault(require("./isRevocableSession"));
var _ParseError = _interopRequireDefault(require("./ParseError"));
var _ParseObject = _interopRequireDefault(require("./ParseObject"));
var _Storage = _interopRequireDefault(require("./Storage"));
const CURRENT_USER_KEY = 'currentUser';
let canUseCurrentUser = !_CoreManager.default.get('IS_NODE');
let currentUserCacheMatchesDisk = false;
let currentUserCache = null;
const authProviders = {};

/**
 * <p>A Parse.User object is a local representation of a user persisted to the
 * Parse cloud. This class is a subclass of a Parse.Object, and retains the
 * same functionality of a Parse.Object, but also extends it with various
 * user specific methods, like authentication, signing up, and validation of
 * uniqueness.</p>
 *
 * @alias Parse.User
 * @augments Parse.Object
 */
class ParseUser extends _ParseObject.default {
  /**
   * @param {object} attributes The initial set of data to store in the user.
   */
  constructor(attributes) {
    super('_User');
    if (attributes && typeof attributes === 'object') {
      if (!this.set(attributes || {})) {
        throw new Error("Can't create an invalid Parse User");
      }
    }
  }

  /**
   * Request a revocable session token to replace the older style of token.
   *
   * @param {object} options
   * @returns {Promise} A promise that is resolved when the replacement
   *   token has been fetched.
   */
  _upgradeToRevocableSession(options) {
    options = options || {};
    const upgradeOptions = {};
    if (options.hasOwnProperty('useMasterKey')) {
      upgradeOptions.useMasterKey = options.useMasterKey;
    }
    const controller = _CoreManager.default.getUserController();
    return controller.upgradeToRevocableSession(this, upgradeOptions);
  }

  /**
   * Parse allows you to link your users with {@link https://docs.parseplatform.org/parse-server/guide/#oauth-and-3rd-party-authentication 3rd party authentication}, enabling
   * your users to sign up or log into your application using their existing identities.
   * Since 2.9.0
   *
   * @see {@link https://docs.parseplatform.org/js/guide/#linking-users Linking Users}
   * @param {string | AuthProvider} provider Name of auth provider or {@link https://parseplatform.org/Parse-SDK-JS/api/master/AuthProvider.html AuthProvider}
   * @param {object} options
   * @param {object} [options.authData] AuthData to link with
   * <ul>
   *   <li>If provider is string, options is {@link http://docs.parseplatform.org/parse-server/guide/#supported-3rd-party-authentications authData}
   *   <li>If provider is AuthProvider, options is saveOpts
   * </ul>
   * @param {object} saveOpts useMasterKey / sessionToken
   * @returns {Promise} A promise that is fulfilled with the user is linked
   */
  linkWith(provider, options) {
    let saveOpts = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    saveOpts.sessionToken = saveOpts.sessionToken || this.getSessionToken() || '';
    let authType;
    if (typeof provider === 'string') {
      authType = provider;
      if (authProviders[provider]) {
        provider = authProviders[provider];
      } else {
        const authProvider = {
          restoreAuthentication() {
            return true;
          },
          getAuthType() {
            return authType;
          }
        };
        authProviders[authProvider.getAuthType()] = authProvider;
        provider = authProvider;
      }
    } else {
      authType = provider.getAuthType();
    }
    if (options && options.hasOwnProperty('authData')) {
      const authData = this.get('authData') || {};
      if (typeof authData !== 'object') {
        throw new Error('Invalid type: authData field should be an object');
      }
      authData[authType] = options.authData;
      const oldAnonymousData = authData.anonymous;
      this.stripAnonymity();
      const controller = _CoreManager.default.getUserController();
      return controller.linkWith(this, authData, saveOpts).catch(e => {
        delete authData[authType];
        this.restoreAnonimity(oldAnonymousData);
        throw e;
      });
    } else {
      return new _promise.default((resolve, reject) => {
        provider.authenticate({
          success: (provider, result) => {
            const opts = {};
            opts.authData = result;
            this.linkWith(provider, opts, saveOpts).then(() => {
              resolve(this);
            }, error => {
              reject(error);
            });
          },
          error: (provider, error) => {
            reject(error);
          }
        });
      });
    }
  }

  /**
   * @param provider
   * @param options
   * @param {object} [options.authData]
   * @param saveOpts
   * @deprecated since 2.9.0 see {@link https://parseplatform.org/Parse-SDK-JS/api/master/Parse.User.html#linkWith linkWith}
   * @returns {Promise}
   */
  _linkWith(provider, options) {
    let saveOpts = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    return this.linkWith(provider, options, saveOpts);
  }

  /**
   * Synchronizes auth data for a provider (e.g. puts the access token in the
   * right place to be used by the Facebook SDK).
   *
   * @param provider
   */
  _synchronizeAuthData(provider) {
    if (!this.isCurrent() || !provider) {
      return;
    }
    let authType;
    if (typeof provider === 'string') {
      authType = provider;
      provider = authProviders[authType];
    } else {
      authType = provider.getAuthType();
    }
    const authData = this.get('authData');
    if (!provider || !authData || typeof authData !== 'object') {
      return;
    }
    const success = provider.restoreAuthentication(authData[authType]);
    if (!success) {
      this._unlinkFrom(provider);
    }
  }

  /**
   * Synchronizes authData for all providers.
   */
  _synchronizeAllAuthData() {
    const authData = this.get('authData');
    if (typeof authData !== 'object') {
      return;
    }
    for (const key in authData) {
      this._synchronizeAuthData(key);
    }
  }

  /**
   * Removes null values from authData (which exist temporarily for unlinking)
   */
  _cleanupAuthData() {
    if (!this.isCurrent()) {
      return;
    }
    const authData = this.get('authData');
    if (typeof authData !== 'object') {
      return;
    }
    for (const key in authData) {
      if (!authData[key]) {
        delete authData[key];
      }
    }
  }

  /**
   * Unlinks a user from a service.
   *
   * @param {string | AuthProvider} provider Name of auth provider or {@link https://parseplatform.org/Parse-SDK-JS/api/master/AuthProvider.html AuthProvider}
   * @param {object} options MasterKey / SessionToken
   * @returns {Promise} A promise that is fulfilled when the unlinking
   *     finishes.
   */
  _unlinkFrom(provider, options) {
    return this.linkWith(provider, {
      authData: null
    }, options).then(() => {
      this._synchronizeAuthData(provider);
      return _promise.default.resolve(this);
    });
  }

  /**
   * Checks whether a user is linked to a service.
   *
   * @param {object} provider service to link to
   * @returns {boolean} true if link was successful
   */
  _isLinked(provider) {
    let authType;
    if (typeof provider === 'string') {
      authType = provider;
    } else {
      authType = provider.getAuthType();
    }
    const authData = this.get('authData') || {};
    if (typeof authData !== 'object') {
      return false;
    }
    return !!authData[authType];
  }

  /**
   * Deauthenticates all providers.
   */
  _logOutWithAll() {
    const authData = this.get('authData');
    if (typeof authData !== 'object') {
      return;
    }
    for (const key in authData) {
      this._logOutWith(key);
    }
  }

  /**
   * Deauthenticates a single provider (e.g. removing access tokens from the
   * Facebook SDK).
   *
   * @param {object} provider service to logout of
   */
  _logOutWith(provider) {
    if (!this.isCurrent()) {
      return;
    }
    if (typeof provider === 'string') {
      provider = authProviders[provider];
    }
    if (provider && provider.deauthenticate) {
      provider.deauthenticate();
    }
  }

  /**
   * Class instance method used to maintain specific keys when a fetch occurs.
   * Used to ensure that the session token is not lost.
   *
   * @returns {object} sessionToken
   */
  _preserveFieldsOnFetch() {
    return {
      sessionToken: this.get('sessionToken')
    };
  }

  /**
   * Returns true if <code>current</code> would return this user.
   *
   * @returns {boolean} true if user is cached on disk
   */
  isCurrent() {
    const current = ParseUser.current();
    return !!current && current.id === this.id;
  }

  /**
   * Returns true if <code>current</code> would return this user.
   *
   * @returns {Promise<boolean>} true if user is cached on disk
   */
  async isCurrentAsync() {
    const current = await ParseUser.currentAsync();
    return !!current && current.id === this.id;
  }
  stripAnonymity() {
    const authData = this.get('authData');
    if (authData && typeof authData === 'object' && authData.hasOwnProperty('anonymous')) {
      // We need to set anonymous to null instead of deleting it in order to remove it from Parse.
      authData.anonymous = null;
    }
  }
  restoreAnonimity(anonymousData) {
    if (anonymousData) {
      const authData = this.get('authData');
      authData.anonymous = anonymousData;
    }
  }

  /**
   * Returns get("username").
   *
   * @returns {string}
   */
  getUsername() {
    const username = this.get('username');
    if (username == null || typeof username === 'string') {
      return username;
    }
    return '';
  }

  /**
   * Calls set("username", username, options) and returns the result.
   *
   * @param {string} username
   */
  setUsername(username) {
    this.stripAnonymity();
    this.set('username', username);
  }

  /**
   * Calls set("password", password, options) and returns the result.
   *
   * @param {string} password User's Password
   */
  setPassword(password) {
    this.set('password', password);
  }

  /**
   * Returns get("email").
   *
   * @returns {string} User's Email
   */
  getEmail() {
    const email = this.get('email');
    if (email == null || typeof email === 'string') {
      return email;
    }
    return '';
  }

  /**
   * Calls set("email", email) and returns the result.
   *
   * @param {string} email
   * @returns {boolean}
   */
  setEmail(email) {
    return this.set('email', email);
  }

  /**
   * Returns the session token for this user, if the user has been logged in,
   * or if it is the result of a query with the master key. Otherwise, returns
   * undefined.
   *
   * @returns {string} the session token, or undefined
   */
  getSessionToken() {
    const token = this.get('sessionToken');
    if (token == null || typeof token === 'string') {
      return token;
    }
    return '';
  }

  /**
   * Checks whether this user is the current user and has been authenticated.
   *
   * @returns {boolean} whether this user is the current user and is logged in.
   */
  authenticated() {
    const current = ParseUser.current();
    return !!this.get('sessionToken') && !!current && current.id === this.id;
  }

  /**
   * Signs up a new user. You should call this instead of save for
   * new Parse.Users. This will create a new Parse.User on the server, and
   * also persist the session on disk so that you can access the user using
   * <code>current</code>.
   *
   * <p>A username and password must be set before calling signUp.</p>
   *
   * @param {object} attrs Extra fields to set on the new user, or null.
   * @param {object} options
   * @returns {Promise} A promise that is fulfilled when the signup
   *     finishes.
   */
  signUp(attrs, options) {
    options = options || {};
    const signupOptions = {};
    if (options.hasOwnProperty('useMasterKey')) {
      signupOptions.useMasterKey = options.useMasterKey;
    }
    if (options.hasOwnProperty('installationId')) {
      signupOptions.installationId = options.installationId;
    }
    if (options.hasOwnProperty('context') && Object.prototype.toString.call(options.context) === '[object Object]') {
      signupOptions.context = options.context;
    }
    const controller = _CoreManager.default.getUserController();
    return controller.signUp(this, attrs, signupOptions);
  }

  /**
   * Logs in a Parse.User. On success, this saves the session to disk,
   * so you can retrieve the currently logged in user using
   * <code>current</code>.
   *
   * <p>A username and password must be set before calling logIn.</p>
   *
   * @param {object} options
   * @returns {Promise} A promise that is fulfilled with the user when
   *     the login is complete.
   */
  logIn() {
    let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    options = options || {};
    const loginOptions = {
      usePost: true
    };
    if (options.hasOwnProperty('useMasterKey')) {
      loginOptions.useMasterKey = options.useMasterKey;
    }
    if (options.hasOwnProperty('installationId')) {
      loginOptions.installationId = options.installationId;
    }
    if (options.hasOwnProperty('usePost')) {
      loginOptions.usePost = options.usePost;
    }
    if (options.hasOwnProperty('context') && Object.prototype.toString.call(options.context) === '[object Object]') {
      loginOptions.context = options.context;
    }
    const controller = _CoreManager.default.getUserController();
    return controller.logIn(this, loginOptions);
  }

  /**
   * Wrap the default save behavior with functionality to save to local
   * storage if this is current user.
   *
   * @param {...any} args
   * @returns {Promise}
   */
  async save() {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    await super.save.apply(this, args);
    const current = await this.isCurrentAsync();
    if (current) {
      return _CoreManager.default.getUserController().updateUserOnDisk(this);
    }
    return this;
  }

  /**
   * Wrap the default destroy behavior with functionality that logs out
   * the current user when it is destroyed
   *
   * @param {...any} args
   * @returns {Parse.User}
   */
  async destroy() {
    for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      args[_key2] = arguments[_key2];
    }
    await super.destroy.apply(this, args);
    const current = await this.isCurrentAsync();
    if (current) {
      return _CoreManager.default.getUserController().removeUserFromDisk();
    }
    return this;
  }

  /**
   * Wrap the default fetch behavior with functionality to save to local
   * storage if this is current user.
   *
   * @param {...any} args
   * @returns {Parse.User}
   */
  async fetch() {
    for (var _len3 = arguments.length, args = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
      args[_key3] = arguments[_key3];
    }
    await super.fetch.apply(this, args);
    const current = await this.isCurrentAsync();
    if (current) {
      return _CoreManager.default.getUserController().updateUserOnDisk(this);
    }
    return this;
  }

  /**
   * Wrap the default fetchWithInclude behavior with functionality to save to local
   * storage if this is current user.
   *
   * @param {...any} args
   * @returns {Parse.User}
   */
  async fetchWithInclude() {
    for (var _len4 = arguments.length, args = new Array(_len4), _key4 = 0; _key4 < _len4; _key4++) {
      args[_key4] = arguments[_key4];
    }
    await super.fetchWithInclude.apply(this, args);
    const current = await this.isCurrentAsync();
    if (current) {
      return _CoreManager.default.getUserController().updateUserOnDisk(this);
    }
    return this;
  }

  /**
   * Verify whether a given password is the password of the current user.
   *
   * @param {string} password The password to be verified.
   * @param {object} options The options.
   * @param {boolean} [options.ignoreEmailVerification] Set to `true` to bypass email verification and verify
   * the password regardless of whether the email has been verified. This requires the master key.
   * @returns {Promise} A promise that is fulfilled with a user when the password is correct.
   */
  verifyPassword(password, options) {
    const username = this.getUsername() || '';
    return ParseUser.verifyPassword(username, password, options);
  }
  static readOnlyAttributes() {
    return ['sessionToken'];
  }

  /**
   * Adds functionality to the existing Parse.User class.
   *
   * @param {object} protoProps A set of properties to add to the prototype
   * @param {object} classProps A set of static properties to add to the class
   * @static
   * @returns {Parse.User} The newly extended Parse.User class
   */
  static extend(protoProps, classProps) {
    if (protoProps) {
      for (const prop in protoProps) {
        if (prop !== 'className') {
          (0, _defineProperty.default)(ParseUser.prototype, prop, {
            value: protoProps[prop],
            enumerable: false,
            writable: true,
            configurable: true
          });
        }
      }
    }
    if (classProps) {
      for (const prop in classProps) {
        if (prop !== 'className') {
          (0, _defineProperty.default)(ParseUser, prop, {
            value: classProps[prop],
            enumerable: false,
            writable: true,
            configurable: true
          });
        }
      }
    }
    return ParseUser;
  }

  /**
   * Retrieves the currently logged in ParseUser with a valid session,
   * either from memory or localStorage, if necessary.
   *
   * @static
   * @returns {Parse.Object} The currently logged in Parse.User.
   */
  static current() {
    if (!canUseCurrentUser) {
      return null;
    }
    const controller = _CoreManager.default.getUserController();
    return controller.currentUser();
  }

  /**
   * Retrieves the currently logged in ParseUser from asynchronous Storage.
   *
   * @static
   * @returns {Promise} A Promise that is resolved with the currently
   *   logged in Parse User
   */
  static currentAsync() {
    if (!canUseCurrentUser) {
      return _promise.default.resolve(null);
    }
    const controller = _CoreManager.default.getUserController();
    return controller.currentUserAsync();
  }

  /**
   * Signs up a new user with a username (or email) and password.
   * This will create a new Parse.User on the server, and also persist the
   * session in localStorage so that you can access the user using
   * {@link #current}.
   *
   * @param {string} username The username (or email) to sign up with.
   * @param {string} password The password to sign up with.
   * @param {object} attrs Extra fields to set on the new user.
   * @param {object} options
   * @static
   * @returns {Promise} A promise that is fulfilled with the user when
   *     the signup completes.
   */
  static signUp(username, password, attrs, options) {
    attrs = attrs || {};
    attrs.username = username;
    attrs.password = password;
    const user = new this(attrs);
    return user.signUp({}, options);
  }

  /**
   * Logs in a user with a username (or email) and password. On success, this
   * saves the session to disk, so you can retrieve the currently logged in
   * user using <code>current</code>.
   *
   * @param {string} username The username (or email) to log in with.
   * @param {string} password The password to log in with.
   * @param {object} options
   * @static
   * @returns {Promise} A promise that is fulfilled with the user when
   *     the login completes.
   */
  static logIn(username, password, options) {
    if (typeof username !== 'string') {
      return _promise.default.reject(new _ParseError.default(_ParseError.default.OTHER_CAUSE, 'Username must be a string.'));
    } else if (typeof password !== 'string') {
      return _promise.default.reject(new _ParseError.default(_ParseError.default.OTHER_CAUSE, 'Password must be a string.'));
    }
    const user = new this();
    user._finishFetch({
      username: username,
      password: password
    });
    return user.logIn(options);
  }

  /**
   * Logs in a user with a username (or email) and password, and authData. On success, this
   * saves the session to disk, so you can retrieve the currently logged in
   * user using <code>current</code>.
   *
   * @param {string} username The username (or email) to log in with.
   * @param {string} password The password to log in with.
   * @param {object} authData The authData to log in with.
   * @param {object} options
   * @static
   * @returns {Promise} A promise that is fulfilled with the user when
   *     the login completes.
   */
  static logInWithAdditionalAuth(username, password, authData, options) {
    if (typeof username !== 'string') {
      return _promise.default.reject(new _ParseError.default(_ParseError.default.OTHER_CAUSE, 'Username must be a string.'));
    }
    if (typeof password !== 'string') {
      return _promise.default.reject(new _ParseError.default(_ParseError.default.OTHER_CAUSE, 'Password must be a string.'));
    }
    if (Object.prototype.toString.call(authData) !== '[object Object]') {
      return _promise.default.reject(new _ParseError.default(_ParseError.default.OTHER_CAUSE, 'Auth must be an object.'));
    }
    const user = new this();
    user._finishFetch({
      username: username,
      password: password,
      authData
    });
    return user.logIn(options);
  }

  /**
   * Logs in a user with an objectId. On success, this saves the session
   * to disk, so you can retrieve the currently logged in user using
   * <code>current</code>.
   *
   * @param {string} userId The objectId for the user.
   * @static
   * @returns {Promise} A promise that is fulfilled with the user when
   *     the login completes.
   */
  static loginAs(userId) {
    if (!userId) {
      throw new _ParseError.default(_ParseError.default.USERNAME_MISSING, 'Cannot log in as user with an empty user id');
    }
    const controller = _CoreManager.default.getUserController();
    const user = new this();
    return controller.loginAs(user, userId);
  }

  /**
   * Logs in a user with a session token. On success, this saves the session
   * to disk, so you can retrieve the currently logged in user using
   * <code>current</code>.
   *
   * @param {string} sessionToken The sessionToken to log in with.
   * @param {object} options
   * @static
   * @returns {Promise} A promise that is fulfilled with the user when
   *     the login completes.
   */
  static become(sessionToken, options) {
    if (!canUseCurrentUser) {
      throw new Error('It is not memory-safe to become a user in a server environment');
    }
    options = options || {};
    const becomeOptions = {
      sessionToken: sessionToken
    };
    if (options.hasOwnProperty('useMasterKey')) {
      becomeOptions.useMasterKey = options.useMasterKey;
    }
    const controller = _CoreManager.default.getUserController();
    const user = new this();
    return controller.become(user, becomeOptions);
  }

  /**
   * Retrieves a user with a session token.
   *
   * @param {string} sessionToken The sessionToken to get user with.
   * @param {object} options
   * @static
   * @returns {Promise} A promise that is fulfilled with the user is fetched.
   */
  static me(sessionToken) {
    let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const controller = _CoreManager.default.getUserController();
    const meOptions = {
      sessionToken: sessionToken
    };
    if (options.useMasterKey) {
      meOptions.useMasterKey = options.useMasterKey;
    }
    const user = new this();
    return controller.me(user, meOptions);
  }

  /**
   * Logs in a user with a session token. On success, this saves the session
   * to disk, so you can retrieve the currently logged in user using
   * <code>current</code>. If there is no session token the user will not logged in.
   *
   * @param {object} userJSON The JSON map of the User's data
   * @static
   * @returns {Promise} A promise that is fulfilled with the user when
   *     the login completes.
   */
  static hydrate(userJSON) {
    const controller = _CoreManager.default.getUserController();
    const user = new this();
    return controller.hydrate(user, userJSON);
  }

  /**
   * Static version of {@link https://parseplatform.org/Parse-SDK-JS/api/master/Parse.User.html#linkWith linkWith}
   *
   * @param provider
   * @param options
   * @param {object} [options.authData]
   * @param saveOpts
   * @static
   * @returns {Promise}
   */
  static logInWith(provider, options, saveOpts) {
    const user = new this();
    return user.linkWith(provider, options, saveOpts);
  }

  /**
   * Logs out the currently logged in user session. This will remove the
   * session from disk, log out of linked services, and future calls to
   * <code>current</code> will return <code>null</code>.
   *
   * @param {object} options
   * @static
   * @returns {Promise} A promise that is resolved when the session is
   *   destroyed on the server.
   */
  static logOut() {
    let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    const controller = _CoreManager.default.getUserController();
    return controller.logOut(options);
  }

  /**
   * Requests a password reset email to be sent to the specified email address
   * associated with the user account. This email allows the user to securely
   * reset their password on the Parse site.
   *
   * @param {string} email The email address associated with the user that
   *     forgot their password.
   * @param {object} options
   * @static
   * @returns {Promise}
   */
  static requestPasswordReset(email, options) {
    options = options || {};
    const requestOptions = {};
    if (options.hasOwnProperty('useMasterKey')) {
      requestOptions.useMasterKey = options.useMasterKey;
    }
    const controller = _CoreManager.default.getUserController();
    return controller.requestPasswordReset(email, requestOptions);
  }

  /**
   * Request an email verification.
   *
   * @param {string} email The email address associated with the user that
   *     needs to verify their email.
   * @param {object} options
   * @static
   * @returns {Promise}
   */
  static requestEmailVerification(email, options) {
    options = options || {};
    const requestOptions = {};
    if (options.hasOwnProperty('useMasterKey')) {
      requestOptions.useMasterKey = options.useMasterKey;
    }
    const controller = _CoreManager.default.getUserController();
    return controller.requestEmailVerification(email, requestOptions);
  }

  /**
   * Verify whether a given password is the password of the current user.
   * @static
   *
   * @param {string} username  The username of the user whose password should be verified.
   * @param {string} password The password to be verified.
   * @param {object} options The options.
   * @param {boolean} [options.ignoreEmailVerification] Set to `true` to bypass email verification and verify
   * the password regardless of whether the email has been verified. This requires the master key.
   * @returns {Promise} A promise that is fulfilled with a user when the password is correct.
   */
  static verifyPassword(username, password, options) {
    if (typeof username !== 'string') {
      return _promise.default.reject(new _ParseError.default(_ParseError.default.OTHER_CAUSE, 'Username must be a string.'));
    }
    if (typeof password !== 'string') {
      return _promise.default.reject(new _ParseError.default(_ParseError.default.OTHER_CAUSE, 'Password must be a string.'));
    }
    const controller = _CoreManager.default.getUserController();
    return controller.verifyPassword(username, password, options || {});
  }

  /**
   * Allow someone to define a custom User class without className
   * being rewritten to _User. The default behavior is to rewrite
   * User to _User for legacy reasons. This allows developers to
   * override that behavior.
   *
   * @param {boolean} isAllowed Whether or not to allow custom User class
   * @static
   */
  static allowCustomUserClass(isAllowed) {
    _CoreManager.default.set('PERFORM_USER_REWRITE', !isAllowed);
  }

  /**
   * Allows a legacy application to start using revocable sessions. If the
   * current session token is not revocable, a request will be made for a new,
   * revocable session.
   * It is not necessary to call this method from cloud code unless you are
   * handling user signup or login from the server side. In a cloud code call,
   * this function will not attempt to upgrade the current token.
   *
   * @param {object} options
   * @static
   * @returns {Promise} A promise that is resolved when the process has
   *   completed. If a replacement session token is requested, the promise
   *   will be resolved after a new token has been fetched.
   */
  static enableRevocableSession(options) {
    options = options || {};
    _CoreManager.default.set('FORCE_REVOCABLE_SESSION', true);
    if (canUseCurrentUser) {
      const current = ParseUser.current();
      if (current) {
        return current._upgradeToRevocableSession(options);
      }
    }
    return _promise.default.resolve();
  }

  /**
   * Enables the use of become or the current user in a server
   * environment. These features are disabled by default, since they depend on
   * global objects that are not memory-safe for most servers.
   *
   * @static
   */
  static enableUnsafeCurrentUser() {
    canUseCurrentUser = true;
  }

  /**
   * Disables the use of become or the current user in any environment.
   * These features are disabled on servers by default, since they depend on
   * global objects that are not memory-safe for most servers.
   *
   * @static
   */
  static disableUnsafeCurrentUser() {
    canUseCurrentUser = false;
  }

  /**
   * When registering users with {@link https://parseplatform.org/Parse-SDK-JS/api/master/Parse.User.html#linkWith linkWith} a basic auth provider
   * is automatically created for you.
   *
   * For advanced authentication, you can register an Auth provider to
   * implement custom authentication, deauthentication.
   *
   * @param provider
   * @see {@link https://parseplatform.org/Parse-SDK-JS/api/master/AuthProvider.html AuthProvider}
   * @see {@link https://docs.parseplatform.org/js/guide/#custom-authentication-module Custom Authentication Module}
   * @static
   */
  static _registerAuthenticationProvider(provider) {
    authProviders[provider.getAuthType()] = provider;
    // Synchronize the current user with the auth provider.
    ParseUser.currentAsync().then(current => {
      if (current) {
        current._synchronizeAuthData(provider.getAuthType());
      }
    });
  }

  /**
   * @param provider
   * @param options
   * @param {object} [options.authData]
   * @param saveOpts
   * @deprecated since 2.9.0 see {@link https://parseplatform.org/Parse-SDK-JS/api/master/Parse.User.html#logInWith logInWith}
   * @static
   * @returns {Promise}
   */
  static _logInWith(provider, options, saveOpts) {
    const user = new this();
    return user.linkWith(provider, options, saveOpts);
  }
  static _clearCache() {
    currentUserCache = null;
    currentUserCacheMatchesDisk = false;
  }
  static _setCurrentUserCache(user) {
    currentUserCache = user;
  }
}
_ParseObject.default.registerSubclass('_User', ParseUser);
const DefaultController = {
  updateUserOnDisk(user) {
    const path = _Storage.default.generatePath(CURRENT_USER_KEY);
    const json = user.toJSON();
    delete json.password;
    json.className = '_User';
    let userData = (0, _stringify.default)(json);
    if (_CoreManager.default.get('ENCRYPTED_USER')) {
      const crypto = _CoreManager.default.getCryptoController();
      userData = crypto.encrypt(json, _CoreManager.default.get('ENCRYPTED_KEY'));
    }
    return _Storage.default.setItemAsync(path, userData).then(() => {
      return user;
    });
  },
  removeUserFromDisk() {
    const path = _Storage.default.generatePath(CURRENT_USER_KEY);
    currentUserCacheMatchesDisk = true;
    currentUserCache = null;
    return _Storage.default.removeItemAsync(path);
  },
  setCurrentUser(user) {
    currentUserCache = user;
    user._cleanupAuthData();
    user._synchronizeAllAuthData();
    return DefaultController.updateUserOnDisk(user);
  },
  currentUser() {
    if (currentUserCache) {
      return currentUserCache;
    }
    if (currentUserCacheMatchesDisk) {
      return null;
    }
    if (_Storage.default.async()) {
      throw new Error('Cannot call currentUser() when using a platform with an async ' + 'storage system. Call currentUserAsync() instead.');
    }
    const path = _Storage.default.generatePath(CURRENT_USER_KEY);
    let userData = _Storage.default.getItem(path);
    currentUserCacheMatchesDisk = true;
    if (!userData) {
      currentUserCache = null;
      return null;
    }
    if (_CoreManager.default.get('ENCRYPTED_USER')) {
      const crypto = _CoreManager.default.getCryptoController();
      userData = crypto.decrypt(userData, _CoreManager.default.get('ENCRYPTED_KEY'));
    }
    userData = JSON.parse(userData);
    if (!userData.className) {
      userData.className = '_User';
    }
    if (userData._id) {
      if (userData.objectId !== userData._id) {
        userData.objectId = userData._id;
      }
      delete userData._id;
    }
    if (userData._sessionToken) {
      userData.sessionToken = userData._sessionToken;
      delete userData._sessionToken;
    }
    const current = _ParseObject.default.fromJSON(userData);
    currentUserCache = current;
    current._synchronizeAllAuthData();
    return current;
  },
  currentUserAsync() {
    if (currentUserCache) {
      return _promise.default.resolve(currentUserCache);
    }
    if (currentUserCacheMatchesDisk) {
      return _promise.default.resolve(null);
    }
    const path = _Storage.default.generatePath(CURRENT_USER_KEY);
    return _Storage.default.getItemAsync(path).then(userData => {
      currentUserCacheMatchesDisk = true;
      if (!userData) {
        currentUserCache = null;
        return _promise.default.resolve(null);
      }
      if (_CoreManager.default.get('ENCRYPTED_USER')) {
        const crypto = _CoreManager.default.getCryptoController();
        userData = crypto.decrypt(userData.toString(), _CoreManager.default.get('ENCRYPTED_KEY'));
      }
      userData = JSON.parse(userData);
      if (!userData.className) {
        userData.className = '_User';
      }
      if (userData._id) {
        if (userData.objectId !== userData._id) {
          userData.objectId = userData._id;
        }
        delete userData._id;
      }
      if (userData._sessionToken) {
        userData.sessionToken = userData._sessionToken;
        delete userData._sessionToken;
      }
      const current = _ParseObject.default.fromJSON(userData);
      currentUserCache = current;
      current._synchronizeAllAuthData();
      return _promise.default.resolve(current);
    });
  },
  signUp(user, attrs, options) {
    const username = attrs && attrs.username || user.get('username');
    const password = attrs && attrs.password || user.get('password');
    if (!username || !username.length) {
      return _promise.default.reject(new _ParseError.default(_ParseError.default.OTHER_CAUSE, 'Cannot sign up user with an empty username.'));
    }
    if (!password || !password.length) {
      return _promise.default.reject(new _ParseError.default(_ParseError.default.OTHER_CAUSE, 'Cannot sign up user with an empty password.'));
    }
    return user.save(attrs, options).then(() => {
      // Clear the password field
      user._finishFetch({
        password: undefined
      });
      if (canUseCurrentUser) {
        return DefaultController.setCurrentUser(user);
      }
      return user;
    });
  },
  logIn(user, options) {
    const RESTController = _CoreManager.default.getRESTController();
    const stateController = _CoreManager.default.getObjectStateController();
    const auth = {
      username: user.get('username'),
      password: user.get('password'),
      authData: user.get('authData')
    };
    return RESTController.request(options.usePost ? 'POST' : 'GET', 'login', auth, options).then(response => {
      user._migrateId(response.objectId);
      user._setExisted(true);
      stateController.setPendingOp(user._getStateIdentifier(), 'username', undefined);
      stateController.setPendingOp(user._getStateIdentifier(), 'password', undefined);
      response.password = undefined;
      user._finishFetch(response);
      if (!canUseCurrentUser) {
        // We can't set the current user, so just return the one we logged in
        return _promise.default.resolve(user);
      }
      return DefaultController.setCurrentUser(user);
    });
  },
  loginAs(user, userId) {
    const RESTController = _CoreManager.default.getRESTController();
    return RESTController.request('POST', 'loginAs', {
      userId
    }, {
      useMasterKey: true
    }).then(response => {
      user._finishFetch(response);
      user._setExisted(true);
      if (!canUseCurrentUser) {
        return _promise.default.resolve(user);
      }
      return DefaultController.setCurrentUser(user);
    });
  },
  become(user, options) {
    const RESTController = _CoreManager.default.getRESTController();
    return RESTController.request('GET', 'users/me', {}, options).then(response => {
      user._finishFetch(response);
      user._setExisted(true);
      return DefaultController.setCurrentUser(user);
    });
  },
  hydrate(user, userJSON) {
    user._finishFetch(userJSON);
    user._setExisted(true);
    if (userJSON.sessionToken && canUseCurrentUser) {
      return DefaultController.setCurrentUser(user);
    } else {
      return _promise.default.resolve(user);
    }
  },
  me(user, options) {
    const RESTController = _CoreManager.default.getRESTController();
    return RESTController.request('GET', 'users/me', {}, options).then(response => {
      user._finishFetch(response);
      user._setExisted(true);
      return user;
    });
  },
  logOut(options) {
    const RESTController = _CoreManager.default.getRESTController();
    if (options.sessionToken) {
      return RESTController.request('POST', 'logout', {}, options);
    }
    return DefaultController.currentUserAsync().then(currentUser => {
      const path = _Storage.default.generatePath(CURRENT_USER_KEY);
      let promise = _Storage.default.removeItemAsync(path);
      if (currentUser !== null) {
        const currentSession = currentUser.getSessionToken();
        if (currentSession && (0, _isRevocableSession.default)(currentSession)) {
          promise = promise.then(() => {
            return RESTController.request('POST', 'logout', {}, {
              sessionToken: currentSession
            });
          });
        }
        currentUser._logOutWithAll();
        currentUser._finishFetch({
          sessionToken: undefined
        });
      }
      currentUserCacheMatchesDisk = true;
      currentUserCache = null;
      return promise;
    });
  },
  requestPasswordReset(email, options) {
    const RESTController = _CoreManager.default.getRESTController();
    return RESTController.request('POST', 'requestPasswordReset', {
      email: email
    }, options);
  },
  async upgradeToRevocableSession(user, options) {
    const token = user.getSessionToken();
    if (!token) {
      return _promise.default.reject(new _ParseError.default(_ParseError.default.SESSION_MISSING, 'Cannot upgrade a user with no session token'));
    }
    options.sessionToken = token;
    const RESTController = _CoreManager.default.getRESTController();
    const result = await RESTController.request('POST', 'upgradeToRevocableSession', {}, options);
    user._finishFetch({
      sessionToken: result?.sessionToken || ''
    });
    const current = await user.isCurrentAsync();
    if (current) {
      return DefaultController.setCurrentUser(user);
    }
    return _promise.default.resolve(user);
  },
  linkWith(user, authData, options) {
    return user.save({
      authData
    }, options).then(() => {
      if (canUseCurrentUser) {
        return DefaultController.setCurrentUser(user);
      }
      return user;
    });
  },
  verifyPassword(username, password, options) {
    const RESTController = _CoreManager.default.getRESTController();
    const data = {
      username,
      password,
      ...(options.ignoreEmailVerification !== undefined && {
        ignoreEmailVerification: options.ignoreEmailVerification
      })
    };
    return RESTController.request('GET', 'verifyPassword', data, options);
  },
  requestEmailVerification(email, options) {
    const RESTController = _CoreManager.default.getRESTController();
    return RESTController.request('POST', 'verificationEmailRequest', {
      email: email
    }, options);
  }
};
_CoreManager.default.setParseUser(ParseUser);
_CoreManager.default.setUserController(DefaultController);
var _default = exports.default = ParseUser;