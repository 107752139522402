"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.default = canBeSerialized;
var _isArray = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/array/is-array"));
var _CoreManager = _interopRequireDefault(require("./CoreManager"));
var _ParseFile = _interopRequireDefault(require("./ParseFile"));
var _ParseRelation = _interopRequireDefault(require("./ParseRelation"));
function canBeSerialized(obj) {
  const ParseObject = _CoreManager.default.getParseObject();
  if (!(obj instanceof ParseObject)) {
    return true;
  }
  const attributes = obj.attributes;
  for (const attr in attributes) {
    const val = attributes[attr];
    if (!canBeSerializedHelper(val)) {
      return false;
    }
  }
  return true;
}
function canBeSerializedHelper(value) {
  if (typeof value !== 'object') {
    return true;
  }
  if (value instanceof _ParseRelation.default) {
    return true;
  }
  const ParseObject = _CoreManager.default.getParseObject();
  if (value instanceof ParseObject) {
    return !!value.id;
  }
  if (value instanceof _ParseFile.default) {
    if (value.url()) {
      return true;
    }
    return false;
  }
  if ((0, _isArray.default)(value)) {
    for (let i = 0; i < value.length; i++) {
      if (!canBeSerializedHelper(value[i])) {
        return false;
      }
    }
    return true;
  }
  for (const k in value) {
    if (!canBeSerializedHelper(value[k])) {
      return false;
    }
  }
  return true;
}