"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _promise = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/promise"));
var _CoreManager = _interopRequireDefault(require("./CoreManager"));
var _isRevocableSession = _interopRequireDefault(require("./isRevocableSession"));
var _ParseObject = _interopRequireDefault(require("./ParseObject"));
var _ParseUser = _interopRequireDefault(require("./ParseUser"));
/**
 * <p>A Parse.Session object is a local representation of a revocable session.
 * This class is a subclass of a Parse.Object, and retains the same
 * functionality of a Parse.Object.</p>
 *
 * @alias Parse.Session
 * @augments Parse.Object
 */
class ParseSession extends _ParseObject.default {
  /**
   * @param {object} attributes The initial set of data to store in the user.
   */
  constructor(attributes) {
    super('_Session');
    if (attributes && typeof attributes === 'object') {
      if (!this.set(attributes || {})) {
        throw new Error("Can't create an invalid Session");
      }
    }
  }

  /**
   * Returns the session token string.
   *
   * @returns {string}
   */
  getSessionToken() {
    const token = this.get('sessionToken');
    if (typeof token === 'string') {
      return token;
    }
    return '';
  }
  static readOnlyAttributes() {
    return ['createdWith', 'expiresAt', 'installationId', 'restricted', 'sessionToken', 'user'];
  }

  /**
   * Retrieves the Session object for the currently logged in session.
   *
   * @param {object} options useMasterKey
   * @static
   * @returns {Promise} A promise that is resolved with the Parse.Session
   * object after it has been fetched. If there is no current user, the
   * promise will be rejected.
   */
  static current(options) {
    options = options || {};
    const controller = _CoreManager.default.getSessionController();
    const sessionOptions = {};
    if (options.hasOwnProperty('useMasterKey')) {
      sessionOptions.useMasterKey = options.useMasterKey;
    }
    return _ParseUser.default.currentAsync().then(user => {
      if (!user) {
        return _promise.default.reject('There is no current user.');
      }
      sessionOptions.sessionToken = user.getSessionToken();
      return controller.getSession(sessionOptions);
    });
  }

  /**
   * Determines whether the current session token is revocable.
   * This method is useful for migrating Express.js or Node.js web apps to
   * use revocable sessions. If you are migrating an app that uses the Parse
   * SDK in the browser only, please use Parse.User.enableRevocableSession()
   * instead, so that sessions can be automatically upgraded.
   *
   * @static
   * @returns {boolean}
   */
  static isCurrentSessionRevocable() {
    const currentUser = _ParseUser.default.current();
    if (currentUser) {
      return (0, _isRevocableSession.default)(currentUser.getSessionToken() || '');
    }
    return false;
  }
}
_ParseObject.default.registerSubclass('_Session', ParseSession);
const DefaultController = {
  getSession(options) {
    const RESTController = _CoreManager.default.getRESTController();
    const session = new ParseSession();
    return RESTController.request('GET', 'sessions/me', {}, options).then(sessionData => {
      session._finishFetch(sessionData);
      session._setExisted(true);
      return session;
    });
  }
};
_CoreManager.default.setSessionController(DefaultController);
var _default = exports.default = ParseSession;