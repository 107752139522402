"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _keys = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/keys"));
const StorageController = {
  async: 0,
  getItem(path) {
    // @ts-ignore
    return wx.getStorageSync(path);
  },
  setItem(path, value) {
    try {
      // @ts-ignore
      wx.setStorageSync(path, value);
    } catch (e) {
      // Quota exceeded
    }
  },
  removeItem(path) {
    // @ts-ignore
    wx.removeStorageSync(path);
  },
  getAllKeys() {
    // @ts-ignore
    const res = wx.getStorageInfoSync();
    return (0, _keys.default)(res);
  },
  clear() {
    // @ts-ignore
    wx.clearStorageSync();
  }
};
module.exports = StorageController;
var _default = exports.default = StorageController;