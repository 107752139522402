"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _stringify = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/json/stringify"));
let AES;
let ENC;
AES = require('crypto-js/aes');
ENC = require('crypto-js/enc-utf8');
const CryptoController = {
  encrypt(obj, secretKey) {
    const encrypted = AES.encrypt((0, _stringify.default)(obj), secretKey);
    return encrypted.toString();
  },
  decrypt(encryptedText, secretKey) {
    const decryptedStr = AES.decrypt(encryptedText, secretKey).toString(ENC);
    return decryptedStr;
  }
};
module.exports = CryptoController;
var _default = exports.default = CryptoController;